<template>
  <GridWithAuth class="veels-details">
    <div class="veels-details__content">
      <h1 class="veels-details__title">
        {{ veelsDetails?.name }}
      </h1>

      <div class="veels-details__block">
        <strong class="title">Vídeos ativos</strong>
        <VeelsDetailsVideos />
      </div>

      <div class="veels-details__block">
        <VeelsDetailsProducts />
      </div>

      <div class="veels-details__block">
        <VeelsDetailsComments />
      </div>
    </div>
  </GridWithAuth>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VeelsDetailsProducts from "./components/VeelsDetailsProducts.vue";
import VeelsDetailsVideos from "./components/VeelsDetailsVideos.vue";
import VeelsDetailsComments from "./components/VeelsDetailsComments.vue";

export default {
  name: "VeelsDetails",

  components: {
    VeelsDetailsProducts,
    VeelsDetailsVideos,
    VeelsDetailsComments
  },

  beforeRouteLeave(to, from, next) {
    this.setVeelsDetails({});
    next(); 
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['veelsDetails', 'confirmationPopupActive']),
  },

  async created () {
    this.loading = true;
    await this.searchVeelsDetails(this.$route.params.veels_id);
    this.loading = false;
  },

  methods: {
    ...mapActions(['searchVeelsDetails', 'setVeelsDetails']),
  }
};
</script>

<style lang="scss" scoped>
.veels-details {
  background-color: $grayscale-100;

  &__content {
    display: flex;
    flex-flow: column;
    padding: 48px;
    width: 100%;
  }

  &__title {
    font-size: 25px;
    width: max-content;
  }

  &__block {
    display: flex;
    flex-flow: column;
    margin-top: 50px;
    gap: 30px;
    background: $white;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid $grayscale-200;

    .title {
      font-weight: normal;
      width: max-content;
      font-size: 16px;
      width: 100%;
      text-align: left;
      padding-bottom: 15px;
      color: darken($grayscale-500, 5%);
    }
  }
}
</style>
