<template>
  <div class="account-container">
    <div class="info">
      <LogoSVG />
      <h2 class="title">
        {{ title }}
      </h2>
      <span class="description">
        {{ description }}
      </span>
    </div>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountContainer',

  props: {
    title: {
      type: String,
      required: false,
      default: () =>  ''
    },
    description: {
      type: String,
      required: false,
      default: () =>  ''
    }
  }
}
</script>

<style lang="scss" scoped>
.account-container {
  width: 500px;
  min-height: 400px;
  background: $white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 5px 5px 15px -5px rgba(0,0,0,0.1);
  display: flex;
  flex-flow: column;
  padding: 30px;
  gap: 40px;

  .info {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    svg {
      width: 200px;
      margin-bottom: 20px;
    }

    .title {
      width: max-content;
      font-size: 25px;
      font-weight: normal;
      color: $black-500;
    }

    .description {
      width: max-content;
      color: $grayscale-400;
    }
  }

  .content {
    width: 100%;
  }
}
</style>