<template>
  <GridWithoutAuth>
    <AccountContainer
      title="Recuperar conta"
      description="Leia as instruções abaixo para recuperar seu conta"
    >
      <!-- v-slot="{ state }" -->
      <FormKit
        type="form"
        :actions="false"
        form-class="account-form"
        @submit="teste"
      >
        <span class="instructions">
          Enviamos um e-mail para "fulano@veels.com" com o código de recuperação da conta.
        </span>

        <FormKit
          type="text"
          placeholder="Digite sua senha"
          label="Código"
          validation="required|length:6"
          :validation-messages="{
            length: 'Código precisa de pelo menos 6 dígitos',
            required: 'Código é obrigatório'
          }"
        />

        <FormKit
          type="password"
          placeholder="Digite sua nova senha"
          label="Nova senha"
          validation="required|length:8"
          :validation-messages="{
            length: 'Senha precisa ter no mínimo 8 digitos',
            required: 'Senha obrigatória'
          }"
        />

        <FormKit
          name="password_confirm"
          type="password"
          label="Confirmar senha"
          placeholder="••••••••"
          validation="confirm"
          :validation-messages="{
            confirm: 'As senhas não são iguais',
          }"
        />

        <div class="actions">
          <ButtonDefault
            class="signup"
            @click="$router.push({ name: 'account-login' })"
          >
            Lembrei da minha senha
          </ButtonDefault>

          <ButtonDefault>
            Recuperar conta
            <ArrowRightSLine />
          </ButtonDefault>
        </div>
      </FormKit>
    </AccountContainer>
  </GridWithoutAuth>
</template>

<script>
import AccountContainer from '../components/AccountContainer.vue';

export default {
  components: {
    AccountContainer,
  }
}
</script>

<style lang="scss" scoped>
.grid-without-auth {
  min-height: 100vh;
  background: $secondary;

  .formkit-form {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .instructions {
      line-height: 23px;
      font-size: 14px;
      color: darken($green-500, 10%);
    }

    .actions {
      display: flex;
      flex-flow: nowrap;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;

      .button-default {
        width: max-content;
        align-self: flex-end;
        width: 200px;

        &.signup {
          background: transparent;
          color: $black-500;
          font-size: 16px;
          justify-content: center;
          width: max-content;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>