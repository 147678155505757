<template>
  <div class="home-hero">
    <div class="home-hero__info">
      <h1 class="home-hero__title">
        Página Inicial
      </h1>

      <div
        v-if="false"
        class="home-hero__dropdown"
      >
        <div class="selected">
          <span class="value">Esta semana</span>
          <ArrowRightSLine />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.home-hero {
  // background: $grayscale-500;
  width: 100%;
  // height: 176px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 48px;
  position: relative;
  margin-top: 48px;

  &__info {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: calc(1172px - 96px);
  }

  &__title {
    color: $black-500;
    font-size: 20px;
    font-weight: 600;
  }

  &__dropdown {
    display: flex;
    flex-flow: column;

    .selected {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .value {
        color: $grayscale-300;
        font-size: 15px;
      }

      svg {
        width: 6px;
        transform: rotate(90deg);
        margin-top: 2px;

        path:last-child {
          stroke: $grayscale-300;
        }
      }
    }
  }
}
</style>
