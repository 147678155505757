export default {
  namespaced: true,

  state: {
    reviews: {},
    reviewsFilters: {
      page: 1,
      per_page: 10,
      type: ''
    },
    reviewsAnalytics: []
  },

  getters: {
    getReviews: state => state.reviews,
  },

  mutations: {
    SET_REVIEWS: (state, value) => {
      Object.assign(state.reviews, { ...value })
    },
    SET_REVIEWS_ANALYTICS: (state, value) => (state.reviewsAnalytics = value)
  },

  actions: {
    setReviewsFilters ({ state }, data) {
      Object.assign(state.commentsFilters, {
        ...data
      })
    },

    setReviews ({ state }, data) {
      state.reviews = data;
    },

    async searchReviews({ commit, state }) {
      let value = null;
      let error = null;

      const {page, per_page } = state.reviewsFilters
      const queryParams = new URLSearchParams({
        page,
        per_page,
      }).toString()

      try {
        const response = await window
          .$request()
          .get(`/reviews/to-list?${queryParams}`)

        commit('SET_REVIEWS', response.data?.reviews);

        value = response.data?.reviews;
      } catch (e) {
        console.error('[searchReviews]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async searchToAnalytics({ commit }) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get(`/reviews/to-analytics`)

        commit('SET_REVIEWS_ANALYTICS', response.data?.reviewSums);

        value = response.data?.reviewSums;
      } catch (e) {
        console.error('[searchReviews]', error)
        error = e;
      }

      return {
        value,
        error
      };
    }
  }
}
