<template>
  <div
    v-if="qrCode"
    class="qr-code"
  >
    <img
      class="image"
      :src="qrCode"
      alt="QR Code"
    >
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: {
    text: {
      type: String,
      required: true,
      default: () => ''
    }
  },

  data() {
    return {
      qrCode: ''
    }
  },

  watch: {
    text: {
      deep: true,
      immediate: true,
      handler() {
        this.generateQRCode();
      }
    }
  },

  methods: {
    generateQRCode() {
      QRCode.toDataURL(this.text)
        .then(url => {
          this.qrCode = url
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  display: flex;
  width: 250px;
  height: 250px;

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
