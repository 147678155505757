<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9.48832L13.5358 13.0233L12.3567 14.2025L10.8333 12.6792V17.3333H9.16667V12.6775L7.64334 14.2025L6.46417 13.0233L10 9.48832ZM10 0.666657C11.4308 0.666725 12.8117 1.19258 13.8802 2.14426C14.9486 3.09593 15.6301 4.40704 15.795 5.82832C16.8319 6.11109 17.7364 6.74924 18.3506 7.63126C18.9647 8.51328 19.2493 9.58307 19.1547 10.6536C19.0601 11.7242 18.5923 12.7275 17.833 13.4882C17.0737 14.2488 16.0712 14.7185 15.0008 14.815V13.1367C15.3843 13.0819 15.753 12.9513 16.0855 12.7525C16.4179 12.5536 16.7075 12.2906 16.9371 11.9787C17.1668 11.6667 17.3321 11.3122 17.4232 10.9357C17.5144 10.5592 17.5296 10.1683 17.4681 9.78588C17.4066 9.40343 17.2694 9.03708 17.0648 8.7082C16.8601 8.37932 16.592 8.0945 16.276 7.87038C15.9601 7.64626 15.6026 7.48731 15.2246 7.40283C14.8466 7.31835 14.4555 7.31002 14.0742 7.37832C14.2047 6.77073 14.1976 6.14163 14.0535 5.53712C13.9094 4.9326 13.6319 4.36798 13.2413 3.8846C12.8508 3.40122 12.357 3.01133 11.7962 2.74349C11.2355 2.47564 10.6219 2.33663 10.0004 2.33663C9.37897 2.33663 8.76538 2.47564 8.20461 2.74349C7.64384 3.01133 7.15008 3.40122 6.75951 3.8846C6.36893 4.36798 6.09143 4.9326 5.94732 5.53712C5.80322 6.14163 5.79616 6.77073 5.92667 7.37832C5.16638 7.23555 4.38052 7.40064 3.74196 7.83729C3.1034 8.27394 2.66445 8.94637 2.52167 9.70666C2.3789 10.4669 2.54399 11.2528 2.98064 11.8914C3.41729 12.5299 4.08972 12.9689 4.85 13.1117L5 13.1367V14.815C3.92955 14.7186 2.92701 14.2491 2.16761 13.4885C1.40822 12.7279 0.940276 11.7246 0.845565 10.654C0.750854 9.5834 1.0354 8.51356 1.64948 7.63147C2.26355 6.74938 3.16809 6.11115 4.205 5.82832C4.36976 4.40697 5.05118 3.09575 6.11965 2.14404C7.18813 1.19234 8.56913 0.666543 10 0.666657Z"
      fill="#000"
    />
  </svg>
</template>
