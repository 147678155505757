<template>
  <div class="upload-veels">
    <div class="upload-veels__actions">
      <ButtonImport
        for="upload-videos"
        icon="InboxUnarchiveLineIcon"
      >
        <template #text>
          Carregar do dispositivo
        </template>
      </ButtonImport>

      <ButtonImport
        icon="GalleryView2Icon"
        @click="toggleUploadFromLibrary(true)"
      >
        <template #text>
          Importar da biblioteca
        </template>
      </ButtonImport>

      <ButtonImport
        icon="SmartphoneLineIcon"
        @click="toggleUploadFromMobile(true)"
      >
        <template #text>
          Enviar do celular
        </template>
      </ButtonImport>
    </div>

    <!-- <span
      class="count"
      :class="{ max: (videosPreview || []).length === 5 }"
    >
      Você importou {{ (videosPreview || []).length }} / 5
    </span> -->

    <FormKit
      id="upload-videos"
      type="file"
      accept="video/*"
      name="videos"
      multiple
      @change="handleFiles"
    />

    <div
      v-if="(newVeelsForm?.videosPreview || []).length"
      class="upload-veels__videos"
    >
      <div
        class="list"
      >
        <div
          v-for="(video, index) in newVeelsForm?.videosPreview"
          :key="index"
          class="item"
        >
          <video
            :src="video.url"
            controls
            width="320"
          />

          <span
            class="remove"
            @click="removeVideo(index)"
          >
            <DeleteBinLineIcon />
          </span>
        </div>
      </div>
      
      <!-- <label
        v-if="uploadedVideos.length < 5"
        class="add"
        for="upload-videos"
      >
        <AddLineIcon />
        <span class="text">Adicionar vídeo</span>
      </label> -->
    </div>

    <div
      v-else
      class="upload-veels__warning"
    >
      Carregue um vídeo a partir do seu dispositivo ou da biblioteca do veels.
    </div>

    <UploadFromLibrary
      v-if="isImportFromLibrary"
      @set-from-library="getVideosFromLibrary"
      @set-close-upload-from-library="closeUploadFromLibrary"
    />

    <UploadFromMobile
      v-if="isImportFromMobile"
      @set-close-upload-from-mobile="closeUploadFromMobile" 
    />

    <ButtonDefault
      :disabled="!checkedUploadStep"
      @click="setNewVeelsStep('product')"
    >
      Salvar e continuar
      <ArrowRightSLine />
    </ButtonDefault>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'UploadVeels',

  data: () => ({
    isImportFromLibrary: false,
    isImportFromMobile: false,
  }),

  computed: {
    ...mapState(['newVeelsForm', 'newVeelsStep']),
    checkedUploadStep () {
      return (this.newVeelsForm?.videos || []).length > 0
    },
  },

  watch: {
    newVeelsStep (step) {
      if (step === 'success') {
        this.uploadedVideos = [];
      }
    },
  },

  mounted () {
    this.emitter.on('toggle-upload-from-library-new-veels', this.toggleUploadFromLibrary);
    this.emitter.on('toggle-upload-from-mobile-new-veels', this.toggleUploadFromMobile);
  },

  unmounted () {
    this.emitter.off('toggle-upload-from-library-new-veels', this.toggleUploadFromLibrary)
    this.emitter.off('toggle-upload-from-mobile-new-veels', this.toggleUploadFromMobile);
  },

  methods: {
    ...mapActions(['setNewVeelsForm', 'setNewVeelsStep', 'setLibraryVideosSelected']),

    toggleUploadFromLibrary(status) {
      this.isImportFromLibrary = status;
    },

    closeUploadFromLibrary(status) {
      this.isImportFromLibrary = status;
      this.setLibraryVideosSelected({ video: {}, status: 'clear' });
    },

    getVideosFromLibrary (libraryVideosSelected) {
      const files = (this.newVeelsForm?.videos || []).concat(libraryVideosSelected)
      const preview = (this.newVeelsForm?.videosPreview || []).concat(libraryVideosSelected)
      this.isImportFromLibrary = false;

      this.setNewVeelsForm({
        videos: [...files ],
        videosPreview: [...preview ]
      })
    },

    toggleUploadFromMobile(status) {
      this.isImportFromMobile = status;
    },

    closeUploadFromMobile (status = false) {
      this.isImportFromMobile = status
    },

    handleFiles(event) {
      const files = event.target.files;
      if (files) {
        let videosPreview = [];
        let filesList = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size <= 15 * 1024 * 1024) { // 10MB em bytes
            videosPreview.push({
              name: file.name,
              url: URL.createObjectURL(file),
            });

            filesList.push(file);
          } else {
            alert(`O arquivo ${file.name} excede o limite de 15MB e não foi adicionado.`);
          }
        }

        if ((this.newVeelsForm?.videos || []).length > 0) {
          console.log(this.newVeelsForm?.videosPreview, 'dentro do if')
          videosPreview = (this.newVeelsForm?.videosPreview || []).concat(videosPreview)
          filesList = (this.newVeelsForm?.videos || []).concat(filesList)
        }

        this.setNewVeelsForm({
          videos: [...filesList],
          videosPreview: [...videosPreview]
        });
        event.target.value = null;
      }
    },

    removeVideo(index) {
      const previews = [...this.newVeelsForm?.videosPreview];
      const files = [...this.newVeelsForm?.videos];

      previews.splice(index, 1);
      files.splice(index, 1);

      this.setNewVeelsForm({
        videos: [...files],
        videosPreview: [...previews]
      })
    },

    setImportFromLibrary () {
      this.isImportFromLibrary = !this.isImportFromLibrary;
    }
  }
}
</script>

<style lang="scss">
.upload-veels {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 50px;

  &__actions {
    display: flex;
    flex-flow: nowrap;
    gap: 44px;

    .button-import {
      width: 140px;
    }
  }

  &__warning {
    margin-top: 50px;
    max-width: 500px;
    line-height: 25px;
    font-size: 15px;
    color: $grayscale-400;
  }

  .count {
    margin: 30px 0;
    font-size: 15px;
    color: $grayscale-500;

    &.max {
      color: $blue-500;
    }
  }

  .formkit-outer {
    display: none;
  }

  &__videos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    height: 160px;
    gap: 20px;

    .list {
      width: auto;
      max-width: 800px;
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      overflow-x: auto;
      gap: 20px;
      height: 160px;

      .item {
        min-width: 120px;
        max-width: 120px;
        height: 150px;
        position: relative;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }

        .remove {
          position: absolute;
          display: flex;
          top: 10px;
          right: 10px;
          z-index: 1;
          background-color: $white;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;

          svg {
            color: $red-500;
            width: 15px;
          }
        }
      }
    }

    // .add {
    //   border: 1px dashed $black-500;
    //   padding: 10px;
    //   border-radius: 20px;
    //   display: flex;
    //   flex-flow: column;
    //   align-items: center;
    //   justify-content: center;
    //   width: 120px;
    //   height: 150px;
    //   gap: 10px;
    //   cursor: pointer;
      
    //   svg {
    //     width: 25px;
    //     color: $black-500;
    //   }

    //   .text {
    //     font-size: 13px;
    //     color: $black-500;
    //   }
    // }
  }

  .button-default {
    width: 212px;
    position: absolute;
    right: 85px;
    bottom: 40px;
  }
}
</style>