import { mapActions, mapState } from "vuex";

export default {
    data: () => ({
        mqttHost: 'wss://mqtt.automizei.app/mqtt',
        mqttOptions: {
            usessl: false,
            keepalive: 60,
            clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
            protocolId: 'MQTT',
            protocolVersion: 4,
            clean: true,
            reconnectPeriod: 1000,
            connectTimeout: 5000,
            username: 'automizei',
            password: 'automizei2023'
        }
    }),

    computed: {
        ...mapState(['user', 'newVeelsForm', 'veelsDetails']),

        mqttTopic() {
            return `veels/${this.user?.email}`;
        }
    },

    methods: {
        ...mapActions(['setNewVeelsForm', 'runLinkVideos', 'setVeelsDetails']),
        initConnectMqtt () {
            if (typeof mqtt !== 'object') return 'Mqtt não foi iniciado corretamente';

            //eslint-disable-next-line
            window.mqtt_client = mqtt.connect(this.mqttHost, this.mqttOptions);
            window.mqtt_client.on('connect', () => {
                console.log('Conectado ao MQTT Broker');

                window.mqtt_client.subscribe(this.mqttTopic, (err) => {
                    if (!err) {
                        window.mqtt_client.on('message',  (topic, message) => {
                            console.log(message.toString());
                            this.getMqttMessage(topic, message.toString());
                        });
                    }
                });
            });
        },

        sendMqttMessage(message) {
            window.mqtt_client.publish(this.mqttTopic, message, {}, (err) => {
                if (err) {
                    console.error('Erro ao enviar a mensagem:', err);
                } else {
                    console.log('Mensagem enviada com sucesso!');
                }
            });
        },

        async getMqttMessage(topic, message) {
            let convertJsonMessage = message.replaceAll("'", '"');
            convertJsonMessage = JSON.parse(convertJsonMessage);
            
            let videos = [];
            let videosPreview = [];

            if (convertJsonMessage?.isComingFromData?.page === 'veels-details') {
                let videos = (this.veelsDetails?.videos || []).length ? [...this.veelsDetails.videos] : [];

                if (convertJsonMessage?.status === 'remove') {
                    this.setVeelsDetails({
                        videos: videos.filter(v => v.video.id != convertJsonMessage?.video?.id)
                    });

                } else {
                    await this.runLinkVideos({
                        veels_id: convertJsonMessage?.isComingFromData?.id,
                        videos: [convertJsonMessage?.video]
                    })


                    videos.push({ video: {...convertJsonMessage?.video} });
                    this.setVeelsDetails({
                        videos
                    });
                }

                this.emitter.emit('toggle-upload-from-mobile-veels-details', false);

                return;
            }

            if (convertJsonMessage?.status === 'remove') {
                videos = this.newVeelsForm?.videos.filter((video) => video.id != convertJsonMessage?.video?.id)
                videosPreview = this.newVeelsForm?.videosPreview.filter((video) => video.id != convertJsonMessage?.video?.id)
            } else {
                videos = (this.newVeelsForm?.videos || []).concat([convertJsonMessage?.video])
                videosPreview = (this.newVeelsForm?.videosPreview || []).concat([convertJsonMessage?.video])
                this.emitter.emit('toggle-upload-from-mobile-new-veels', false);
            }

            this.setNewVeelsForm({
                videos,
                videosPreview
            })
        },

        desconnectMqtt () {
            window.mqtt_client.end(function () {
                console.log('Desconectado do broker MQTT');
            });
        },
    }
}


// import mqtt from 'mqtt';

// class MqttService {
//     constructor() {
//         this.mqttHost = 'wss://mqtt.automizei.app/mqtt';
//         this.mqttOptions = {
//             clean: true,
//             connectTimeout: 5000,
//             clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
//             username: 'automizei',
//             password: 'automizei2023',
//             keepalive: 60,
//         };
//         this.client = null;
//     }

//     connect() {
//         return new Promise((resolve, reject) => {
//             this.client = mqtt.connect(this.mqttHost, this.mqttOptions);
//             this.client.on('connect', () => {
//                 console.log('Conectado ao MQTT Broker');
//                 resolve();
//             });
//             this.client.on('error', (err) => {
//                 console.error('Erro na conexão:', err);
//                 reject(err);
//             });
//         });
//     }

//     subscribe(topic, callback) {
//         this.client.subscribe(topic, (err) => {
//             if (err) {
//                 console.error('Erro ao se inscrever no tópico:', err);
//             } else {
//                 console.log('Inscrito no tópico:', topic);
//             }
//         });

//         this.client.on('message', (topic, message) => {
//             callback(topic, message.toString());
//         });
//     }

//     publish(topic, message) {
//         this.client.publish(topic, message, (err) => {
//             if (err) {
//                 console.error('Erro ao enviar a mensagem:', err);
//             } else {
//                 console.log('Mensagem enviada com sucesso!');
//             }
//         });
//     }

//     disconnect() {
//         this.client.end(() => {
//             console.log('Desconectado do broker MQTT');
//         });
//     }
// }

// export default new MqttService();