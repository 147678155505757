<template>
  <GridWithAuth class="page-veels">
    <CommentsHero />
    <CommentsTabs />
    <CommentsList />
  </GridWithAuth>
</template>

<script>
import CommentsHero from "./components/CommentsHero.vue";
import CommentsTabs from "./components/CommentsTabs.vue";
import CommentsList from "./components/CommentsList.vue";

export default {
  name: "VeelsView",
  components: {
    CommentsHero,
    CommentsTabs,
    CommentsList
  },
};
</script>

<style lang="scss">
.page-home {
  background-color: $grayscale-100;

  .grid-content__page {
    row-gap: 48px;
  }
}
</style>
