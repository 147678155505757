
/**
 * @param text {string}
 * @returns    {Promise<boolean>}
 */
export async function useClipboard (text) {
  return new Promise((resolve) => {
    if (navigator?.clipboard && navigator.permissions) {
      const type = 'text/plain'
      const blob = new Blob([text], { type })
      const data = [new ClipboardItem({ [type]: blob })]

      navigator.permissions.query({ name: 'clipboard-write' }).then(permission => {
        if (['granted', 'prompt'].includes(permission.state)) {
          navigator.clipboard.write(data).then(() => resolve(true), () => resolve(false))
        } else {
          resolve(false)
        }
      })
    } else {
      const textarea = document.createElement('textarea')

      textarea.textContent = text
      textarea.style.position = "fixed"
      textarea.style.width = '2em'
      textarea.style.height = '2em'
      textarea.style.padding = '0'
      textarea.style.border = 'none'
      textarea.style.outline = 'none'
      textarea.style.boxShadow = 'none'
      textarea.style.background = 'transparent'

      document.body.appendChild(textarea)

      textarea.focus()
      textarea.select()

      try {
        document.execCommand('copy')

        resolve(true)
      }
      catch (e) {
        resolve(false)
      } finally {
        document.body.removeChild(textarea)
      }
    }
  })
}
