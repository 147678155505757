<template>
  <div class="upload-from-mobile">
    <span
      class="close"
      @click="$emit('setCloseUploadFromMobile', false)"
    >
      <CloseLineIcon />
    </span>

    <div class="actions">
      <ButtonImport icon="SmartphoneLineIcon">
        <template #text>
          Enviar do celular
        </template>
      </ButtonImport>
    </div>

    <div class="content">
      <div class="steps">
        <strong class="title">Siga os passos abaixo para enviar seus vídeos do celular</strong>
        
        <p class="step">
          1. Scaneie o QR Code abaixo com o seu celular/tablet para iniciar o processo de importação
        </p>

        <p class="step">
          2. No seu celular, você será redirecionado para uma tela de importação dos vídeos, selecione os vídeos do seu telefone e clique em "enviar".
        </p>

        <p class="step">
          3. Ao enviar, aguarde que automáticamente o site irá reconhecer os vídeos enviados. E assim você poderá dar sequência à criação ou edição do seu veels.
        </p>
      </div>

      <QrCode
        v-if="qrCodeUrl"
        :text="qrCodeUrl"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import QrCode from './QrCode.vue'

export default {

  components: {
    QrCode
  },

  props: {
    isComingFromParams: {
      type: Object,
      default: () => {}
    }
  },

  emits: ['setCloseUploadFromMobile'],

  computed: {
    ...mapState(['user']),

    qrCodeUrl () {
      if (!this.user?.token) return false;

      const queryParams = new URLSearchParams({ token: this.user?.token, ...this.isComingFromParams }).toString();
      return `${window.location.origin}/upload-from-mobile?${queryParams}`
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-from-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 10;
  width: 80vw;
  height: 80vh;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 0 85px;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    cursor: pointer;

    svg {
      width: 20px;
      color: $grayscale-400;
    }
  }

  .actions {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    .button-import {
      width: 340px;
      height: 78px;
      flex-flow: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
  }

  .content {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .steps {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: max-content;
      max-width: 90%;
      gap: 15px;
      margin-bottom: 30px;

      .title {
        font-size: 17px;
        color: $black-500;
      }

      .step {
        text-align: left;
        font-size: 15px;
        color: $grayscale-600;
      }
    }

    .qr-code {
      width: 350px;
      height: 350px;
    }
  }
}
</style>
