<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.64706 1.70587H2.2549C1.92208 1.70587 1.60289 1.83808 1.36755 2.07342C1.13221 2.30876 1 2.62795 1 2.96077V11.7451C1 12.0779 1.13221 12.3971 1.36755 12.6324C1.60289 12.8678 1.92208 13 2.2549 13H11.0392C11.372 13 11.6912 12.8678 11.9266 12.6324C12.1619 12.3971 12.2941 12.0779 12.2941 11.7451V7.35293"
      stroke="#000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8278 1.3727C11.0664 1.13406 11.3901 1 11.7276 1C12.0651 1 12.3887 1.13406 12.6273 1.3727C12.866 1.61133 13 1.93498 13 2.27246C13 2.60994 12.866 2.93359 12.6273 3.17223L6.92883 8.87074L4.52945 9.47059L5.12929 7.07121L10.8278 1.3727Z"
      fill="white"
      stroke="#000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
