<template>
  <GridWithAuth class="account-profile">
    <h1 class="account-profile__title">
      Meu perfil
    </h1>

    <div class="account-profile__content">
      <FormKit
        v-slot="{ state }"
        v-model="form"
        type="form"
        :actions="false"
        form-class="account-form"
        incomplete-message="Por favor, preencha os campos corretamente"
        @submit="accountUpdateForm"
      >
        <FormKit
          type="text"
          placeholder="Digite seu e-mail"
          label="E-mail"
          name="email"
          readonly
        />

        <FormKit
          type="text"
          placeholder="Digite seu nome"
          label="Nome"
          name="name"
          validation="required"
          :validation-messages="{
            required: 'Nome obrigatório'
          }"
        />

        <FormKit
          v-model="phoneMasked"
          type="text"
          placeholder="Digite seu nome"
          label="Telefone (Suporte Whatsapp)"
          name="phone"
          maxlength="15"
          validation="length:15"
          :validation-messages="{
            length: 'Digite o telefone corretamente'
          }"
        />

        <!-- <FormKit
          type="password"
          name="password"
          placeholder="Digite sua senha"
          label="Senha"
          validation="length:6"
          :validation-messages="{
            length: 'Senha precisa ter no mínimo 6 digitos',
            required: 'Senha obrigatória'
          }"
        />

        <FormKit
          name="password_confirm"
          type="password"
          label="Confirmar senha"
          placeholder="••••••••"
          validation="confirm"
          :validation-messages="{
            confirm: 'As senhas não são iguais',
          }"
        /> -->

        <FormKit
          type="hidden"
          name="id"
        />

        <small
          v-if="error"
          class="error-message"
        >
          {{ error }}
        </small>

        <small
          v-if="success"
          class="success-message"
        >
          {{ success }}
        </small>

        <div class="actions">
          <ButtonDefault
            :loading="state?.loading"
          >
            Salvar e atualizar
            <ArrowRightSLine />
          </ButtonDefault>

          <ButtonDefault
            class="logout"
            @click="accountLogout"
          >
            Fazer logout
          </ButtonDefault>
        </div>
      </FormKit>
    </div>
  </GridWithAuth>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { usePhoneMask } from "@/config/utils/usePhoneMask";

export default {
  components: {
  },

  data: () => ({
    error: '',
    success: '',
    form: {
      name: "",
      phone: "",
      social_name: "",
      fantasy_name: "",
      cpf: "",
      cnpj: "",
      ie: "",
      logo: "",
      cep: "",
      street: "",
      neighborhood: "",
      number: "",
      complement: "",
      city: "",
      state: "",
      uf: "",
      id: ""
    },
    phone: ''
  }),
  
  computed: {
    ...mapState(['user']),
    phoneMasked: {
      get() {
        return this.phone;
      },

      set(phone) {
        this.phone = usePhoneMask(phone);
      }
    }
  },


  watch: {
    user: {
      deep: true,
      immediate: true,
      handler (user) {
        this.form = { ...user }
      }
    }
  },

  methods: {
    ...mapActions(['accountUpdate', 'accountLogout']),

    async accountUpdateForm (data) {
      delete data.email;
      delete data.password_confirm;
      this.success = "";
      this.error = "";

      const { error } = await this.accountUpdate({
        ...data
      });

      if (error) {
        this.error = 'Falha ao atualizar, reveja os campos ou entre em contato.';
      } else {
        this.success = 'Seus dados foram atualizados!'
      }

      setTimeout(() => {
        this.error = '';
        this.success = '';
      }, 7000);
    }
  }
}
</script>

<style lang="scss" scoped>
.account-profile {
  &__title {
    font-size: 20px;
    padding: 0 40px;
  }

  &__content {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 800px;
    padding: 40px;

    .formkit-form {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .actions {
        display: flex;
        flex-flow: nowrap;
        width: 100%;
        justify-content: space-between;
        margin-top: 20px;

        .button-default {
          width: max-content;
          align-self: flex-end;
          width: 200px;

          &.signup {
            background: transparent;
            color: $black-500;
            font-size: 16px;
            justify-content: center;
            width: max-content;
            padding: 0;
            text-decoration: underline;
          }

          &.logout {
            width: max-content;
            color: $red-500;
            background: transparent;
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>