<template>
  <GridWithAuth class="page-settings">
    <div class="page-settings__content">
      <h1 class="page-settings__title">
        Aprenda a instalar o veels no seu site
      </h1>

      <div class="page-settings__block">
        <strong class="title">Passo 1: Instalação</strong>

        <p class="description">
          Instale o script abaixo no final da tag body do seu site
        </p>

        <div class="code">
          <span
            class="copy"
            @click="copyToClipboard(tutorial?.stript || tutorial?.script)"
          >
            <FileCopyLineIcon />
          </span>

          <div class="value">
            {{ tutorial?.stript || tutorial?.script }}
          </div>
        </div>
      </div>

      <div class="page-settings__block">
        <strong
          class="title"
          title="Copiar código"
        >Passo 2: Configurar chamada do veels</strong>

        <p class="description">
          Faça a chamada de forma simples apenas com a tag "veels", que irá reconhecer seus produtos através da URL acessada pelo navegador, lembrando que essa URL precisa estar atrelada ao "veels" criado por você aqui na plataforma. Cole
          o código abaixo na página do seu produto.
        </p>

        <div class="code">
          <span
            class="copy"
            title="Copiar código"
            @click="copyToClipboard(tagProductVeels2)"
          >
            <FileCopyLineIcon />
          </span>

          <div class="value">
            {{ tagProductVeels2 }}
          </div>
        </div>

        <p class="description">
          Ou
        </p>

        <p class="description">
          Caso você esteja atrelando o SKU dos seus produtos 
          nos "veels" criados, basta aplicar o código abaixo
          passando o atributo "SKU" na tag "veels".
        </p>

        <div class="code">
          <span
            class="copy" 
            title="Copiar código"
            @click="copyToClipboard(tagProductVeels)"
          >
            <FileCopyLineIcon />
          </span>

          <div class="value">
            {{ tagProductVeels }}
          </div>
        </div>
      </div>
    </div>
  </GridWithAuth>
</template>

<script>
import { useClipboard } from "@/config/utils/useClipboard";
import { mapState, useStore } from "vuex";

export default {
  name: "SettingsView",

  setup () {
    const store = useStore();
    store.dispatch('searchTutorial');
  },

  data: () => ({
    tagProductVeels: '<veels sku="SKU-DO-PRODUTO" />',
    tagProductVeels2: '<veels />'
  }),

  computed: {
    ...mapState(['tutorial'])
  },

  methods: {
    async copyToClipboard (value) {
      const copy = await useClipboard(value);

      if (copy) {
        alert('O valor foi copiado para a área de transferencia!');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-settings {
  background-color: $grayscale-100;

  &__content {
    display: flex;
    flex-flow: column;
    padding: 48px;
  }

  &__title {
    font-size: 25px;
    width: max-content;
  }

  &__block {
    display: flex;
    flex-flow: column;
    margin-top: 50px;
    gap: 30px;

    .title {
      width: max-content;
    }

    .description {
      width: max-content;
      font-size: 15px;
      color: darken($grayscale-500, 10%);
      max-width: 700px;
      text-align: left;
      line-height: 23px;
    }

    .code {
      width: 500px;
      background: $white;
      border-radius: 20px;
      border: 1px solid $grayscale-200;
      box-shadow: 3px 3px 15px -5px rgba(0,0,0,0.05);
      display: flex;
      flex-flow: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 30px 20px;
      border: 1px solid $grayscale-200;
      position: relative;

      .copy {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        svg {
          color: $grayscale-400;
          width: 20px;
        }
      }

      .value {
        text-align: left;
        line-height: 25px;
        font-size: 14px;
        color: darken($blue-500, 10%);
      }
    }
  }
}
</style>
