<template>
  <div class="home-actions">
    <h2 class="title">
      Ações rápidas para criar um veel
    </h2>

    <div class="home-actions__content">
      <ButtonImport
        icon="InboxUnarchiveLineIcon"
        @click="setPopupActiveWith(true)"
      >
        <template #text>
          Carregar do dispositivo
        </template>
      </ButtonImport>

      <ButtonImport
        icon="GalleryView2Icon"
        @click="setPopupActiveWith('library')"
      >
        <template #text>
          Importar da biblioteca
        </template>
      </ButtonImport>

      <ButtonImport
        icon="SmartphoneLineIcon"
        @click="setPopupActiveWith('mobile')"
      >
        <template #text>
          Enviar do celular
        </template>
      </ButtonImport>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeActions',
  methods: {
    setPopupActiveWith(action) {
      this.emitter.emit('toggle-popup-new-veels', action);
    },
  }
}
</script>

<style lang="scss" scoped>
.home-actions {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 15px;
  padding: 0 48px;
  width: 100%;
  max-width: 1172px;

  .title {
    color: $black-500;
    font-size: 16px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    gap: 28px;
  }
}
</style>
