
import account from './account';
import global from './global';
import home from './home';
import library from './library';
import newVeels from './new-veels';
import veels from './veels';
import comments from './comments';
import reviews from './reviews';
import settings from './settings';
import UploadFromMobile from './upload-from-mobile';
import { createStore } from 'vuex';

const store = createStore({
  namespaced: true,

  state: {
    ...account.state,
    ...global.state,
    ...newVeels.state,
    ...home.state,
    ...library.state,
    ...veels.state,
    ...comments.state,
    ...settings.state,
    ...UploadFromMobile.state,
    ...reviews.state
  },

  getters: {
    ...account.getters,
    ...global.getters,
    ...newVeels.getters,
    ...home.getters,
    ...library.getters,
    ...veels.getters,
    ...comments.getters,
    ...settings.getters,
    ...UploadFromMobile.getters,
    ...reviews.getters
  },

  mutations: {
    ...account.mutations,
    ...global.mutations,
    ...newVeels.mutations,
    ...home.mutations,
    ...library.mutations,
    ...veels.mutations,
    ...comments.mutations,
    ...settings.mutations,
    ...UploadFromMobile.mutations,
    ...reviews.mutations
  },

  actions: {
    ...account.actions,
    ...global.actions,
    ...newVeels.actions,
    ...home.actions,
    ...library.actions,
    ...veels.actions,
    ...comments.actions,
    ...settings.actions,
    ...UploadFromMobile.actions,
    ...reviews.actions
  }
});

export default store;
