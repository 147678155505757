<template>
  <GlobalTabs
    :tabs="tabs"
    :has-search="false"
    @set-tab-value="getTabValue"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "CommentsTabs",
  computed: {
    tabs () {
      return [
        { label: 'Todos', value: 'to-list' },
        { label: 'Para responder', value: 'to-reply' },
        { label: 'Para aprovar', value: 'to-approve' },
      ]
    }
  },

  methods: {
    ...mapActions(['setCommentsFilters']),
    // getSearchedTerm (term) {
    //   this.setCommentsFilters({
    //     type: term,
    //     page: 1
    //   })
    // },

    getTabValue (value) {
      this.setCommentsFilters({
        type: value,
        page: 1
      })
    }
  }
}
</script>