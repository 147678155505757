
import router from '@/router';
<template>
  <div class="grid-side-menu">
    <div class="grid-side-menu__container">
      <figure
        class="grid-side-menu__logo"
        @click="$router.push({ name: 'home' })"
      >
        <LogoSVG />
      </figure>

      <div class="grid-side-menu__new-veel">
        <ButtonDefault @click="initNewVeels">
          Novo veels
          <AddLineIcon />
        </ButtonDefault>
      </div>

      <div class="grid-side-menu__navigations">
        <ul class="grid-side-menu__navigations-list">
          <li
            class="item"
            :class="{ active: $route.name === 'home' }"
            @click="$router.push({ name: 'home' })"
          >
            <span class="item-title">
              <Home3LineIcon />Página inicial
            </span>
          </li>
          <li
            class="item"
            :class="{ active: $route.name.includes('veels') }"
            @click="$router.push({ name: 'veels' })"
          >
            <span class="item-title">
              <PlayLineIcon />Veels
            </span>
          </li>
          <li
            class="item"
            :class="{ active: $route.name === 'library' }"
            @click="$router.push({ name: 'library' })"
          >
            <span class="item-title">
              <GalleryView2Icon />Biblioteca
            </span>
          </li>
          <li
            class="item"
            :class="{ active: $route.name.includes('comments') }"
            @click="$router.push({ name: 'comments' })"
          >
            <span class="item-title">
              <Chat4LineIcon />Comentários
            </span>
          </li>
          <li
            class="item"
            :class="{ active: $route.name.includes('reviews') }"
            @click="$router.push({ name: 'reviews' })"
          >
            <span class="item-title">
              <StarLineIcon />Avaliações
            </span>
          </li>
          <li
            class="item"
            :class="{ active: $route.name.includes('settings') }"
          >
            <span
              class="item-title"
              @click="$router.push({ name: 'settings-ga4' })"
            >
              <Settings3LineIcon /> Configurar
            </span>

            <ul class="subitems">
              <li
                class="subitem"
                :class="{ active: $route.name === 'settings-ga4' }"
                @click="$router.push({ name: 'settings-ga4' })"
              >
                Google Analytics 4
              </li>

              <li
                class="subitem"
                :class="{ active: $route.name === 'settings' }"
                @click="$router.push({ name: 'settings' })"
              >
                Publicar
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <!-- <ButtonDefault class="grid-side-menu__goshop">
      Visitar minha loja
      <ArrowRightSLine />
    </ButtonDefault> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'GridSideMenu',
  methods: {
    ...mapActions([
      'setNewVeelsForm',
      'setNewVeelsStep'
    ]),

    initNewVeels() {
      this.emitter.emit('toggle-popup-new-veels', true);
    }
  }
}
</script>

<style lang="scss">
.grid-side-menu {
  width: 100%;
    max-width: 268px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    background: #f2efe8;
    padding: 40px 0;
    min-height: 100vh;
    height: 100%;
    border-right: 1px solid $grayscale-300;
    position: sticky;
    top: 0;

  &__container {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  &__logo {
    padding: 0px 71px;
    padding-bottom: 41px;
    cursor: pointer;
  }

  &__new-veel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 28px;
    border-top: 1px solid $grayscale-300;
    border-bottom: 1px solid $grayscale-300;
    width: 100%;
  }

  &__goshop {
    background: $grayscale-500;
    margin: 0 28px;
    width: calc(100% - 56px);
  }

  &__navigations {
    display: flex;
    padding: 56px 28px;
    width: 100%;

    &-list {
      display: flex;
      flex-flow: column;
      gap: 32px;

      .item {
        display: flex;
        flex-flow: column;

        &.active {
          .item-title {
            font-weight: bold;
            color: $black-500;

            svg {
              path:last-child {
                fill: $black-500;
              }
            }
          }
        }

        .item-title {
          display: flex;
          flex-flow: nowrap;
          align-items: center;
          width: max-content;
          gap: 12px;
          font-weight: 500;
          font-size: 14px;
          color: $grayscale-400;
          cursor: pointer;

          svg {
            width: 25px;
            height: 25px;

            &.icon-resize {
              width: 20px;
              height: 20px;
            }

            path:last-child {
              fill: $grayscale-400;
            }
          }
        }

        .subitems {
          display: flex;
          flex-flow: column;
          width: 100%;
          padding-left: 25px;
          margin-left: 12px;
          padding-top: 5px;
          gap: 15px;
          border-left: 1px solid $grayscale-300;
          margin-top: 10px;

          .subitem {
            width: max-content;
            list-style: none;
            font-size: 14px;
            color: $grayscale-400;
            cursor: pointer;

            &.active {
              color: $black-500;
            }
          }
        }
      }
    }
  }
}
</style>
