<template>
  <div class="publish-veels">
    <div class="publish-veels__content">
      <div class="field">
        <label class="field-label">Título do veels (uso interno)</label>
        <div class="field-content">
          <!-- <img
            src="@/assets/img/veels-card.png"
            alt="T-shirt listrada"
          > -->
          <video
            v-if="videoPreviewUrl"
            class="preview"
          >
            <source
              :src="videoPreviewUrl"
              type="video/mp4"
            >
            Seu navegador não suporta a tag de vídeo.
          </video>

          <FormKit
            v-model="name"
            type="text"
            placeholder="qual o título do vídeo?"
          />
        </div>
      </div>

      <div class="field">
        <label class="field-label">Texto da chamada para clique no vídeo</label>
        <div class="field-content">
          <FormKit
            v-model="description"
            type="text"
            placeholder="Ex.: Novo lançamento!"
          />
        </div>
      </div>
    </div>

    <ButtonDefault
      class="back"
      @click="setNewVeelsStep('product')"
    >
      <ArrowRightSLine />
      Voltar
    </ButtonDefault>

    <ButtonDefault
      :disabled="!checkedPublishStep"
      :loading="loading"
      @click="initRunStepsNewVeels"
    >
      Publicar veels
      <CheckBoxCircleLineIcon />
    </ButtonDefault>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'ProductVeels',

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['newVeelsForm']),
    checkedPublishStep () {
      return this.name && this.description
    },

    videoPreviewUrl () {
      if ((this.newVeelsForm?.videosPreview  || []).length < 1) {
        return ''
      }

      return this.newVeelsForm?.videosPreview[0]?.url;
    },

    name: {
      get() {
        return this.newVeelsForm?.name;
      },
      set(name) {
        this.setNewVeelsForm({
          name
        })
      }
    },

    description: {
      get() {
        return this.newVeelsForm?.description;
      },

      set(description) {
        this.setNewVeelsForm({
          description
        })
      }
    }
  },

  methods: {
    ...mapActions(['setNewVeelsStep', 'setNewVeelsForm', 'runStepsNewVeels']),
    
    async initRunStepsNewVeels () {
      this.loading = true;
      const { error } = await this.runStepsNewVeels();

      if (error) {
        return alert(error);
      }

      this.setNewVeelsStep('success');

      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.publish-veels {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 100px;

  &__content {
    background: $grayscale-200;
    display: flex;
    flex-flow: nowrap;
    padding: 24px;
    width: max-content;
    border-radius: 8px;
    gap: 30px;

    .field {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
      width: 320px;
      gap: 10px;

      &-label {
        color: $grayscale-500;
        font-size: 12px;
      }

      &-content {
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        gap: 15px;

        .preview {
          width: 48px;
          height: 48px;
          border-radius: 8px;
          object-fit: cover;
        }

        .formkit-outer {
          .formkit-wrapper {
            .formkit-inner {
              .formkit-input {
                width: 271px;
                height: 40px;
                border-radius: 50px;
                border: 0;
                padding: 0 20px;
                color: $black-500;
              }
            }
          }
        }
      }
    }
  }

  .button-default {
    width: 212px;
    position: absolute;
    right: 85px;
    bottom: 40px;
    background: $green-500;

    &.back {
      left: 85px;
      right: unset;
      justify-content: flex-start;
      gap: 20px;
      width: max-content;
      background: transparent;
      color: $black-500;

      svg {
        transform: rotate(180deg);
        width: 12px;
        height: 12px;

        path:last-child {
          stroke: $black-500;
        }
      }
    }
  }
}
</style>