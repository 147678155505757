<template>
  <div class="reviews-analytics">
    <table class="reviews-analytics__table">
      <tr
        v-for="data, key in notes"
        :key="key"
      >
        <th class="title">
          {{ getTitle(key + 1) }}
        </th>

        <th
          class="progress"
        >
          <div
            class="bar"
          >
            <div
              class="content"
              :style="{ width: `${getWidthProgressBar(key + 1)}%` }"
            />
          </div>
          <span class="total">{{ getTotalByNoteId(key + 1) }}</span>
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ReviwesAnalytics',

  data: () => ({
    notes: [
      'Sim, ajudou muito na minha escolha',
      'Gostei, mas poderia ter mais videos',
      'Não me importei muito com isso',
      'Não, prefiro ficar vendo as imagens',
    ]
  }),

  computed: {
    ...mapState(['reviewsAnalytics']),

    getHighestValue () {
      if ((this.reviewsAnalytics || []).length < 1) return 0;

      return this.reviewsAnalytics.reduce((prev, current) => {
        return (current.total > prev.total) ? current : prev;
      });
    },
  },

  async created() {
    this.searchToAnalytics();
  },

  methods: {
    ...mapActions([
      'searchToAnalytics'
    ]),

    getTitle(id) {
      return {
        '1': 'Sim, ajudou muito na minha escolha',
        '2': 'Gostei, mas poderia ter mais videos',
        '3': 'Não me importei muito com isso',
        '4': 'Não, prefiro ficar vendo as imagens',
      }[id];
    },

    getWidthProgressBar (noteId) {
      const total = this.getTotalByNoteId(noteId)
      return (total / this.getHighestValue.total) * 100
    },

    getTotalByNoteId(noteId) {
      if ((this.reviewsAnalytics || []).length < 1) return 0
      const foundItem = this.reviewsAnalytics.find(item => item.note_id === noteId);
      return foundItem ? foundItem.total : 0;
    }
  }
}
</script>

<style lang="scss">
.reviews-analytics {
  margin: 0 48px;
  background: $white;
  box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.07);
  max-width: 750px;
  width: 100%;
  padding: 16px 22px;
  border-radius: 8px;

  &__table {
    width: 100%;
    table-layout: fixed;

    th, td {
      padding: 10px 0px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
    }

    th {
      position: relative;

      &.title {
        width: 40%;
        font-size: 15px;
      }

      &.progress {
        width: 60%;
        padding-left: 20px;

        .bar {
          width: calc(100% - 20px);
          margin-left: 20px;

          .content {
            background: $black-500;
            height: 10px;
            border-radius: 50px;
          }
        }

        .total {
          color: $black-500;
          position: absolute;
          left: 20px;
          top: 12px;
          z-index: 2;
          font-size: 12px;
        }
      }
    }
  }
}
</style>