<template>
  <div class="reviews">
    <div
      v-if="(reviewsData || []).length > 0"
      class="reviews-list"
    >
      <div
        v-for="review in reviewsData"
        :key="review.id"
        class="reviews-list__item"
      >
        <div class="reviews-list__content">
          <div class="reviews-list__option">
            {{ getOptionText(review?.note.id) }}
          </div>

          <a
            class="reviews-list__product-btn"
            :href="goToVeelsDetails(review?.veels_id)"
            target="_blank"
          >
            Ver produto
          </a>
        </div>

        <div
          v-if="review?.message != '-'"
          class="reviews-list__message"
        >
          <label class="reviews-list__label">Mensagem enviada pelo cliente:</label>
          <FormKit
            type="text"
            :value="review?.message"
            disabled
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="empty"
    >
      Nenhuma avaliação encontrada.
    </div>

    <Pagination
      v-if="(reviewsData || []).length"
      :data="paginationData"
      @navigate="page => setPage(page)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ReviwesList',

  data: () => ({
    loading: false,
    error: {},
    success: {}
  }),

  computed: {
    ...mapState(['reviews', 'reviewsFilters', 'reviewsAnalytics']),

    reviewsData() {
      return this.reviews?.data || [];
    },

    paginationData (store) {
      const last_page    = store.reviews?.last_page ?? 1
      const current_page = store.reviews?.current_page ?? 1

      return {
        last_page,
        current_page
      }
    },

    setPage (store) {
      return (page) => store.reviewsFilters.page = page
    }
  },

  watch: {
    reviewsFilters: {
      deep: true,
      async handler () {
        await this.searchReviews();
      }
    }
  },

  async created() {
    this.loading = true;
    this.searchToAnalytics();
    await this.searchReviews()
    this.loading = false;
  },

  methods: {
    ...mapActions([
      'searchReviews',
      'searchToAnalytics'
    ]),

    getOptionText(id) {
      return {
        '1': 'Sim, ajudou muito na minha escolha',
        '2': 'Gostei, mas poderia ter mais videos',
        '3': 'Não me importei muito com isso',
        '4': 'Não, prefiro ficar vendo as imagens',
      }[id];
    },

    goToVeelsDetails(id) {
      return this.$router.resolve({
        name: 'veels-details',
        params: { veels_id: id }
      }).href
    }
  }
}
</script>

<style lang="scss">
.reviews {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 50px;
  width: calc(100% - 96px);
  padding: 0px 0px;
  margin: 0 48px;

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grayscale-500;
    width: calc(100% - 96px);
    max-width: 750px;
    padding: 0px 28px;
    margin: 20px 0;
  }

  .reviews-list {
    margin-top: 30px;
    width: 100%;
    gap: 15px;
    display: flex;
    flex-flow: column;
    max-width: 750px;

    &__item {
      display: flex;
      flex-flow: column;
      gap: 20px;
      background: $white;
      box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.07);
      padding: 16px 22px;
      border-radius: 8px;
    }

    &__content {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__option {
      text-align: left;
    }

    &__product-btn {
      width: max-content;
      padding: 10px 0px;
      border-radius: 50px;
      color: $black-500;
      font-size: 14px;
      cursor: pointer;
    }

    &__message {
      display: flex;
      flex-flow: column;
      gap: 5px;
      margin-top: 10px;

      &.no-message {
        .formkit-outer {
          .formkit-wrapper {
            .formkit-inner {
              .formkit-input {
                background: transparent;
                border: 0;
                height: auto;
                padding: 0;
              }
            }
          }
        }
      }
    }

    &__label {
      font-size: 15px;
      color: $grayscale-500;
      text-align: left;
    }
  }

  .pagination {
    max-width: 750px; 
  }
}
</style>