import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from 'mitt';
import globalComponents from "./config/globalComponents";
import request from './config/request';
import { plugin, defaultConfig } from '@formkit/vue'
import formatCurrency from './config/filters/currency';

window.$request = request;
window.$router = router;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Veels';
  next();
});

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.$formatCurrency = formatCurrency;
app.config.globalProperties.emitter = emitter;

app.use(store);
app.use(router);
app.use(plugin, defaultConfig);
app.use(globalComponents);

store.dispatch('accountAuth');

app.mount("#app");
