<template>
  <div class="library-actions">
    <div class="library-actions__upload">
      <ButtonImport icon="GalleryView2Icon">
        <template #text>
          Importar da biblioteca
        </template>
      </ButtonImport>

      <ButtonImport icon="FocusLineIcon">
        <template #text>
          Gravação instantânea
        </template>
      </ButtonImport>
    </div>

    <ButtonDefault>
      Utilizar arquivos
      <ArrowRightSLine />
    </ButtonDefault>
  </div>
</template>

<style lang="scss">
.library-actions {
  padding: 0 48px;
  margin-top: 34px;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__upload {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    gap: 20px;

    .button-import {
      width: 340px;
      height: 78px;
      flex-flow: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .text {
        max-width: unset;
      }
    }
  }

  .button-default {
    width: max-content;
  }
}
</style>