<template>
  <div class="comments comments-veels">
    <div
      v-if="(commentsData || []).length > 0"
      class="comments-list"
    >
      <div
        v-for="comment in commentsData"
        :key="comment.id"
        class="comments-list__item"
      >
        <div class="comments-list__item-container">
          <div class="comments-list__item-content">
            <div class="comments-list__item-info user">
              <div class="comments-list__item-info-container">
                <figure class="image">
                  <span class="first-letter">
                    {{ (comment.name || 'V').charAt(0) }}
                  </span>
                </figure>

                <strong class="name">
                  {{ comment.name }}
                </strong>

                <ButtonDefault
                  v-if="comment.phone"
                  class="phone"
                  @click="whatsappLink(comment?.phone)"
                >
                  <WhatsappLineIcon />

                  <span class="value">{{ comment.phone }}</span>
                </ButtonDefault>
              </div>

              <div class="comments-list__item-info actions">
                <a
                  v-if="!comment?.status"
                  class="btn approve"
                  @click="setCommentApprove(comment?.id)"
                >
                  Aprovar
                </a>
              </div>
            </div>

            <div class="comments-list__item-info comment">
              {{ comment?.comment }}
            </div>
          </div>
        </div>

        <div class="comments-list__item-replies">
          <ul class="list">
            <li
              v-for="reply in comment?.replies"
              :key="reply.id"
              class="item"
            >
              <b>Resposta:</b> {{ reply?.reply }}
            </li>
          </ul>
        </div>

        <div class="comments-list__item-reply">
          <FormKit
            :id="`form-reply-${comment.id}`"
            v-slot="{ state }"
            type="form"
            class="global-tabs__filters"
            :actions="false"
            @submit="sendReply"
          >
            <FormKit 
              type="textarea"
              placeholder="Digite sua resposta"
              name="reply"
              validation="required"
              :validation-messages="{
                required: 'Campo obrigatório'
              }"
            />

            <FormKit
              name="id"
              :value="comment?.id"
              type="hidden"
            />

            <ButtonDefault
              :loading="state.loading"
            >
              Responder
            </ButtonDefault>
          </FormKit>

          <small
            v-if="error[`reply_${comment?.id}`]"
            class="error-message"
          >
            {{ error[`reply_${comment?.id}`] }}
          </small>

          <small
            v-if="success[`reply_${comment?.id}`]"
            class="success-message"
          >
            {{ success[`reply_${comment?.id}`] }}
          </small>
        </div>
      </div>
    </div>

    <div
      v-else
      class="empty"
    >
      Nenhum comentário encontrado.
    </div>

    <Pagination
      v-if="(commentsData || []).length"
      :data="paginationData"
      @navigate="page => setPage(page)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CommentsList',

  data: () => ({
    loading: false,
    error: {},
    success: {}
  }),

  computed: {
    ...mapState(['comments', 'commentsFilters', 'user']),

    commentsData() {
      return this.comments?.comments?.data || [];
    },

    paginationData (store) {
      const last_page    = store.comments?.last_page ?? 1
      const current_page = store.comments?.current_page ?? 1

      return {
        last_page,
        current_page
      }
    },

    setPage (store) {
      return (page) => store.commentsFilters.page = page
    }
  },

  watch: {
    commentsFilters: {
      deep: true,
      async handler () {
        this.searchComments();
      }
    }
  },

  async created() {
    this.loading = true;
    this.setCommentsFilters({
        type: `to-veels/${this.$route?.params?.veels_id}`,
        page: 1
    })
    this.loading = false;
  },

  beforeUnmount() {
    this.setComments({});
    this.setCommentsFilters({
        type: '',
        page: 1
    })
  },

  methods: {
    ...mapActions([
        'searchComments', 
        'sendCommentReply',
        'setCommentApprove', 
        'setCommentsFilters',
        'setComments'
    ]),

    async sendReply (data) {
      this.success = {};
      this.error = {};

      const { error } = await this.sendCommentReply({
        ...data
      });

      if (error) {
        this.error[`reply_${data.id}`] = error.response?.data?.error || 'Erro desconhecido, por favor entre em contato com nossa equipe.';
      } else {
        this.success[`reply_${data.id}`] = 'Sua resposta foi enviada'
        this.$formkit.reset(`form-reply-${data.id}`);
      }

      setTimeout(() => {
        this.error[`reply_${data.id}`] = '';
        this.success[`reply_${data.id}`] = '';
      }, 7000);
    },

    goToVeelsDetails(id) {
      return this.$router.resolve({
        name: 'veels-details',
        params: { veels_id: id }
      }).href
    }
  }
}
</script>

<style lang="scss">
.comments-veels {
    margin: 0 !important;
    width: 100% !important;

    .comments-list {
      max-width: 100% !important;

      &__item {
        box-shadow: unset !important;
      }
    }
}
</style>