<template>
  <GridWithAuth class="settings-ga4">
    <h1 class="settings-ga4__title">
      Configure seu Google Analytics 4
    </h1>

    <div class="step">
      <span class="step-title">
        Passo 1:
      </span>

      <div class="description">
        <b>Dê permissão de leitura para o veels em seu GA4:</b>
        Acesse o seu Google Analytics 4, clique em configurações do administrador > Configurações da propriedade > Propriedade > Gerenciamento de Acesso. Em seguida, adicione o e-mail abaixo como “Leitor”.
      </div>

      <div class="copy-email">
        <span
          class="icon"
          title="Copiar código"
          @click="copyToClipboard(email)"
        >
          <FileCopyLineIcon />
        </span>

        <input
          class="value"
          :value="email"
        >
      </div>

      <div class="description image">
        Ainda tem dúvidas? Veja na imagem como encontrar 
        <a
          class="link"
          @click="setPopupImage(2)"
        >
          clicando aqui.
        </a>
      </div>

      <PopupImage
        :data="dataPopupImage"
        @close-popup-image="getClosePopupImage"
      />
    </div>

    <div class="step">
      <span class="step-title">
        Passo 2:
      </span>

      <div class="description">
        <b>Vamos salvar o número do ID da propriedade do seu GA4:</b>
        Acesse o seu Google Analytics 4, clique em configurações do administrador > Configurações da propriedade > Propriedade > Detalhes da Propriedade, lá você irá encontrar o “ID DA PROPRIEDADE: 123456789”
      </div>

      <div class="settings-ga4__content">
        <FormKit
          v-slot="{ state }"
          type="form"
          :actions="false"
          form-class="settings-ga4-form"
          incomplete-message="Por favor, preencha os campos corretamente"
          @submit="setGoogleID"
        >
          <FormKit
            v-model="googleID"
            type="text"
            placeholder="Digite o ID da propriedade"
            label="ID de propriedade"
            name="google_id"
          />

          <ButtonDefault
            :loading="state?.loading"
          >
            Salvar
          </ButtonDefault>
        </FormKit>

        <small
          v-if="error"
          class="error-message"
        >
          {{ error }}
        </small>

        <small
          v-if="success"
          class="success-message"
        >
          {{ success }}
        </small>
      </div>

      <div class="description image">
        Ainda tem dúvidas? Veja na imagem como encontrar 
        <a
          class="link"
          @click="setPopupImage(1)"
        >clicando aqui</a>
      </div>
    </div>
  </GridWithAuth>
</template>

<script>
import { useClipboard } from "@/config/utils/useClipboard";
import { mapActions, mapState } from 'vuex';
import PopupImage from "./components/PopupImage.vue"
export default {
  components: {
    PopupImage
  },

  data: () => ({
    error: '',
    success: '',
    email: 'google-analytics@serene-tooling-436518-n1.iam.gserviceaccount.com',
    dataPopupImage: {}
  }),
  
  computed: {
    ...mapState(['user']),
    googleID: {
      get() {
        return this.user?.google_id;
      },

      set(value) {
        this.google_id = value;
      }
    }
  },

  methods: {
    ...mapActions(['updateGoogleID']),

    getClosePopupImage(status) {
      this.dataPopupImage = {
        active: status,
        image: '',
        description: ''
      }
    },

    setPopupImage (step) {
      switch (step) {
        case 1:
          this.dataPopupImage = {
            active: true,
            image: '/ga4/step-1-property-id.png',
            description: 'Encontre o ID da propriedade do seu GA4'
          }
          break;
        case 2:
          this.dataPopupImage = {
            active: true,
            image: '/ga4/step-2-email-access.png',
            description: 'Veja como dar a permissão para o veels no painel do GA4'
          }
          break;
        default:
          this.dataPopupImage = {
            active: false,
            image: '',
            description: ''
          }
      }
    },

    async setGoogleID (data) {
      this.success = "";
      this.error = "";

      const { error } = await this.updateGoogleID({
        ...data
      });

      if (error) {
        this.error = 'Falha ao salvar, reveja os campos ou entre em contato.';
      } else {
        this.success = 'Google Analytics salvo.'
      }

      setTimeout(() => {
        this.error = '';
        this.success = '';
      }, 7000);
    },

    async copyToClipboard (value) {
      const copy = await useClipboard(value);

      if (copy) {
        alert('O valor foi copiado para a área de transferencia!');
      }
    }
  }
}
</script>

<style lang="scss">
.settings-ga4 {
  &__title {
    font-size: 20px;
    padding: 0 40px;
    margin-top: 48px;
    margin-bottom: 30px;
  }

  .step {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 40px;
    width: 800px;

    &-title {
      font-size: 16px;
      color: darken($grayscale-500, 30%);
      margin-bottom: 20px;
      background: $grayscale-200;
      padding: 10px 20px;
      border-radius: 50px;
      text-align: left;
    }

    .description {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      color: darken($grayscale-500, 30%);
      text-align: left;
      line-height: 25px;
      font-size: 15px;

      &.image {
        margin-top: 10px;
        font-size: 14px;
        flex-flow: wrap;
        gap: 3px;

        .link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .copy-email {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      position: relative;
      width: 500px;
      text-align: left;
      background: $white;
      padding: 15px 20px;
      box-shadow: 0 0 15px -3px rgba(0,0,0,0.1);
      border-radius: 8px;
      margin-top: 20px;

      .value {
        width: 80%;
        height: 40px;
        font-size: 16px;
        border: 0;
        background: transparent;
        color: $grayscale-600;
      }

      .icon {
        position: absolute;
        right: 10px;
        cursor: pointer;

        svg {
          color: $grayscale-600;
          width: 25px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 15px;

    .formkit-form {
      flex-flow: nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      height: 100%;

      .formkit-outer {
        max-width: 400px;
      }

      .button-default {
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 55px;
      }
    }

    .error-message, .success-message {
      margin-top: 5px;
    }
  }
}
</style>