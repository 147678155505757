import { createRouter, createWebHistory } from "vue-router";
import { getCookie } from '@/config/utils/cookies';
import Home from "../views/home";
import Veels from "../views/veels";
import VeelsDetails from "../views/veels-details";
import Library from "../views/library";
import Settings from "../views/settings";
import SettingsGa4 from "../views/settings-ga4";
import Comments from "../views/comments";
import Reviews from "../views/reviews";
import AccountLogin from "../views/account/login";
import AccountRegister from "../views/account/register";
import AccountRecovery from "../views/account/recovery";
import AccountProfile from "../views/account/profile";
import UploadFromMobile from "../views/upload-from-mobile"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Home | Veels"
    }
  },
  {
    path: "/veels",
    name: "veels",
    component: Veels,
    meta: {
      requiresAuth: true,
      title: "Lista de Veels Publicados | Veels"
    }
  },
  {
    path: "/veels-details/:veels_id",
    name: "veels-details",
    component: VeelsDetails,
    meta: {
      requiresAuth: true,
      title: "Detalhe do Veels | Veels"
    }
  },
  {
    path: "/library",
    name: "library",
    component: Library,
    meta: {
      requiresAuth: true,
      title: "Galeria de Videos | Veels"
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: "Configurações | Veels"
    }
  },
  {
    path: "/upload-from-mobile",
    name: "upload-from-mobile",
    component: UploadFromMobile,
    meta: {
      requiresAuth: true,
      title: "Enviar do celular | Veels"
    }
  },
  {
    path: "/settings-ga4",
    name: "settings-ga4",
    component: SettingsGa4,
    meta: {
      requiresAuth: true,
      title: "Configurar GA4 | Veels"
    }
  },
  {
    path: "/comments",
    name: "comments",
    component: Comments,
    meta: {
      requiresAuth: true,
      title: "Comentários | Veels"
    }
  },
  {
    path: "/reviews",
    name: "reviews",
    component: Reviews,
    meta: {
      requiresAuth: true,
      title: "Reviews | Veels"
    }
  },
  {
    path: '/account',
    redirect: { name: 'login' },
    meta: {
      title: "Autenticação | Veels"
    },
    children: [
      {
        name: 'account-login',
        path: 'login',
        component: AccountLogin,
        meta: {
          title: "Login | Veels"
        },
      },
      {
        name: 'account-register',
        path: 'register',
        component: AccountRegister,
        meta: {
          title: "Cadastrar | Veels"
        },
      },
      {
        name: 'account-recovery',
        path: 'recovery',
        component: AccountRecovery,
        meta: {
          title: "Recuperar conta | Veels"
        },
      },
      {
        name: 'account-profile',
        path: 'profile',
        component: AccountProfile,
        meta: {
          requiresAuth: true,
          meta: {
            title: "Meu perfil | Veels"
          },
        }
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const tokenFromUrl = to.query?.token;
    const token = getCookie('user_token') || tokenFromUrl;

    if (token && (token || '').length > 10) {
      next();
    } else {
      next('/account/login');
    }
  } else {
    next();
  }
});

export default router;
