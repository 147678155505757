<template>
  <div
    class="home-metric__box"
    :class="classStatusBox"
  >
    <div class="home-metric__box-header">
      <strong class="title">
        {{ data?.title }}
      </strong>

      <span
        v-if="data?.percent != null"
        class="percent"
      >
        <ArrowScaleUpIcon />
        {{ data?.percent > 0 ? "+" : "" }}{{ data?.percent }}%
      </span>

      <span
        v-if="data?.id == 'revenue' && data?.value_1 > 0"
        class="icons"
      >
        Uhuul 
        <img
          :src="require('@/assets/img/metrics/dollar-face.png')"
          alt="receita"
        >

        <img
          :src="require('@/assets/img/metrics/party-face.png')"
          alt="receita"
        >
      </span>
    </div>

    <div
      class="values"
      :class="{ column: data?.value_3 }"
    >
      <div class="value">
        <strong class="title"> {{ data?.value_1 }} </strong>
        <!--eslint-disable-next-line-->
        <div class="description" v-html="data?.description_1" />
      </div>

      <div
        v-if="data?.value_2"
        class="value"
      >
        <strong class="title"> {{ data?.value_2 }} </strong>
        <!--eslint-disable-next-line-->
        <div class="description" v-html="data?.description_2" />
      </div>

      <div
        v-if="data?.value_3"
        class="value"
      >
        <strong class="title"> {{ data?.value_3 }} </strong>
        <!--eslint-disable-next-line-->
        <div class="description" v-html="data?.description_3" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

  computed: {
    classStatusBox () {
      return {
        'down': this.data?.percent < 0,
        'up': this.data?.percent > 0,
        'zero': this.data?.percent == 0
      }
    }
  }
};
</script>

<style lang="scss">
.home-metric__box {
  width: 340px;
  min-height: 138px;
  background-color: $white;
  padding: 20px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $grayscale-300;

  &.up {
    .percent {
      background-color: lighten($green-500, 53%);
      color: $green-500;

      svg {
        path {
          stroke: $green-500;
        }
      }
    }
  }

  &.down {
    .percent {
      background-color: lighten($red-500, 33%);
      color: $red-500;
      svg {
        transform: scaleY(-1);

        path {
          stroke: $red-500;
        }
      }
    }
  }

  &.zero {
    .percent {
      background-color: lighten($grayscale-500, 33%);
      color: lighten($grayscale-500, 10%);

      svg {
        display: none;
      }
    }
  }

  &-header {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .percent {
      padding: 4px 8px;
      border-radius: 40px;
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: bold;

      svg {
        width: 15px;
      }
    }

    .icons {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      font-size: 14px;
      color: $black-500;
      gap: 5px;

      img {
        width: 20px;
      }
    }

    .title {
      font-size: 14px;
      color: $grayscale-400;
    }
  }

  .values {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    &.column {
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 33px;
      gap: 30px;
    }

    .value {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      gap: 3px;

      .title {
        color: $black-500;
        font-size: 20px;
      }

      .description {
        color: $grayscale-500;
        font-size: 12px;
        font-family: "Nunito", "sans-serif";
      }
    }
  }
}
</style>
