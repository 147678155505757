export default {
  namespaced: true,

  state: {
    homeShowcaseVideos: false,
    homeHeroMetrics: {},
    homeAnalytics: {}
  },

  getters: {
    getHomeShowcaseVideos: state => state.homeShowcaseVideos,
    getHomeHeroMetrics: state => state.homeHeroMetrics,
    getHomeAnalytics: state => state.homeAnalytics
  },

  mutations: {
    SET_HOME_SHOWCASE_VIDEOS: (state, value) => (state.homeShowcaseVideos = value),
    SET_HOME_HERO_METRICS: (state, value) => (state.homeHeroMetrics = value),
    SET_HOME_ANALYTICS: (state, value) => (state.homeAnalytics = value)
  },

  actions: {
    async searchHomeShowcaseVideos({ commit }) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get('/gallery/search')

        commit('SET_HOME_SHOWCASE_VIDEOS', response.data);

        value = response.data;
      } catch (e) {
        console.error('[searchHomeShowcaseVideos]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async searchHomeHeroMetrics({ commit }) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get('/analytics/search?grouped=true')

        commit('SET_HOME_HERO_METRICS', response.data);

        value = response.data;
      } catch (e) {
        console.error('[searchHomeShowcaseVideos]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async searchHomeAnalytics({ commit }, data) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get(`/auth/analytics?start=${data.start}&end=${data.end}`)

        commit('SET_HOME_ANALYTICS', response.data);
        // commit('SET_HOME_ANALYTICS', {});

        value = response.data;
      } catch (e) {
        console.error('[searchHomeShowcaseVideos]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },
  }
}
