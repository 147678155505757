export default {
  namespaced: true,

  state: {
    confirmationPopupActive: false,
  },

  getters: {
    getConfirmationPopupActive: state => state.confirmationPopupActive,
  },

  mutations: {
    SET_CONFIRMATION_POPUP_ACTIVE: (state, value) => (state.confirmationPopupActive = value)
  },

  actions: {
    setConfirmationPopupActive({ commit }, status) {
      commit('SET_CONFIRMATION_POPUP_ACTIVE', status);
    },
  }
}
