<template>
  <GridWithoutAuth>
    <div class="upload-from-mobile__page">
      <figure class="logo">
        <LogoSVG />
      </figure>

      <strong class="title">Olá, {{ getFirstName }}</strong>

      <p class="description">
        Clique no box abaixo para selecionar um arquivo do seu dispositivo.
      </p>

      <div class="content">
        <UploadFromMobileVideos />
      </div>
    </div>
  </GridWithoutAuth>
</template>

<script>
import { mapState } from 'vuex';
import UploadFromMobileVideos from './components/UploadFromMobileVideos.vue'

export default {
  components: {
    UploadFromMobileVideos
  },

  computed: {
    ...mapState(['user']),

    getFirstName() {
      let firstName = '';
      if (this.user?.name?.includes(' ')) {
        firstName = this.user.name.split(' ');
      }

      return firstName ? firstName[0] : this.user?.name;
    }
  },

  mounted () {
  }

}
</script>

<style lang="scss" scoped>
.upload-from-mobile__page {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 28px;

  .title {
    font-size: 20px;
    margin-top: 30px;
    color: $black-500;
    margin-bottom: 20px;
  }

  .description {
    color: $grayscale-600;
    line-height: 25px;
  }

  .content {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}
</style>