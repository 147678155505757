<template>
  <div class="grid-without-auth">
    <div class="grid-without-auth__page">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.grid-without-auth {
  display: flex;
  flex-flow: nowrap;
  width: 100%;

  &__page {
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}

</style>
  