<template>
  <div
    v-if="data?.active"
    class="popup-image"
  >
    <div
      class="popup-image__background"
      @click="$emit('closePopupImage', false)"
    />

    <div class="popup-image__content">
      <span
        class="popup-image__close"
        @click="$emit('closePopupImage', false)"
      >
        <CloseLineIcon />
      </span>

      <span
        v-if="data?.description"
        class="description"
      >
        {{ data?.description }}
      </span>

      <img
        :src="image"
        alt="GA4 Tutorial"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupImage',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },

  emits: ['closePopupImage'],

  computed: {
    image () {
      return require(`@/assets/img${this.data?.image}`)
    }
  }
}
</script>

<style lang="scss">
.popup-image {
  &__background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    z-index: 10;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 11;
    cursor: pointer;

    svg {
      width: 30px;
      color: $white;
    }
  }

  &__content {
    width: 80vw;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .description {
      font-size: 16px;
      margin-bottom: 20px;
      background: $white;
      border-radius: 50px;
      width: max-content;
      padding: 7px 20px;
      color: $black-500;
    }

    img {
      width: 100%;
      max-height: 80vh;
      object-fit: contain;
    }
  }
}
</style>