<template>
  <div class="veels">
    <div
      v-if="(veelsData || []).length > 0"
      class="veels-list"
    >
      <div
        v-for="veel in veelsData"
        :key="veel.id"
        class="veels-list__item"
      >
        <div class="veels-list__item-info product">
          <figure class="image">
            <!-- <img
              src="@/assets/img/veels-card.png"
              alt="Product Name"
            > -->
            <span class="first-letter">
              {{ (veel.name || 'V').charAt(0) }}
            </span>

            <!-- <span class="icon">
              <EditBoxLineIcon /> 
            </span> -->
          </figure>

          <strong class="name">
            {{ veel.name }}
          </strong>
        </div>

        <div class="veels-list__item-info">
          <div class="data">
            <EyeLineIcon />

            <span class="data-text">{{ veel.clicks || 0 }} views</span>
          </div>

          <div class="data">
            <ChartLineIcon />

            <span class="data-text">{{ veel.loads || 0 }} abertura</span>
          </div>

          <!-- <div class="data">
            <MoneyDollarBoxLineIcon />

            <span class="data-text">R$ 2.342,71</span>
          </div> -->

          <div class="data">
            <Heart3LineIcon />

            <span class="data-text">{{ veel.likes || 0 }} curtidas</span>
          </div>
        </div>

        <div class="veels-list__item-info">
          <button
            class="btn"
            @click="$router.push({
              name: 'veels-details',
              params: { veels_id: veel.id }
            })"
          >
            Editar
          </button>
        </div>
      </div>
    </div>

    <div
      v-else
      class="empty"
    >
      Nenhum Veels encontrado, <a
        class="new"
        @click="emitter.emit('toggle-popup-new-veels', true)"
      >clique aqui</a> para criar um novo.
    </div>

    <Pagination
      v-if="(veelsData || []).length"
      :data="paginationData"
      @navigate="page => setPage(page)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'VeelsList',

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['veels', 'veelsFilters']),

    veelsData() {
      return this.veels?.veels?.data || [];
    },

    paginationData (store) {
      const last_page    = store.veels?.veels?.last_page ?? 1
      const current_page = store.veels?.veels?.current_page ?? 1

      return {
        last_page,
        current_page
      }
    },

    setPage (store) {
      return (page) => store.veelsFilters.page = page
    }
  },

  watch: {
    veelsFilters: {
      deep: true,
      async handler () {
        this.searchVeels()
      }
    }
  },

  async created() {
    this.loading = true;
    await this.searchVeels();
    this.loading = false;
  },

  methods: {
    ...mapActions(['searchVeels']),
  }
}
</script>

<style lang="scss">
.veels {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 50px;
  width: calc(100% - 96px);
  padding: 0px 0px;
  margin: 0 48px;

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: $grayscale-500;
    width: calc(100% - 96px);
    padding: 0px 28px;
    margin: 0 48px;
  }

  .veels-list {
    margin-top: 40px;
    width: 100%;
    gap: 15px;
    display: flex;
    flex-flow: column;
    max-width: 950px;

    &__item {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      background: $white;
      border: 1px solid $grayscale-200;
      padding: 16px 22px;
      border-radius: 8px;

      &-info {
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        gap: 33px;

        &.product {
          gap: 20px;
          max-width: 263px;
          min-width: 263px;
        }

        .image {
          display: flex;
          width: max-content;
          margin: 0;
          position: relative;

          .first-letter {
            width: 48px;
            height: 48px;
            background: $black-500;
            color: $white;
            font-size: 20px;
            font-weight: bold;
            border-radius: 8px;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          // img {
          //   width: 48px;
          //   height: 48px;
          //   object-fit: cover;
          //   border-radius: 4px;
          // }
          // .icon {
          //   width: 15px;
          //   height: 15px;
          //   background: $white;
          //   position: absolute;
          //   top: -5px;
          //   right: -5px;
          //   border-radius: 50%;

          //   svg {
          //     width: 10px;
          //     path {
          //       stroke: $grayscale-500;
          //     }
          //   }
          // }
        }

        .name {
          color: $black-500;
          font-size: 14px;
        }

        .data {
          display: flex;
          flex-flow: nowrap;
          gap: 7px;
          align-items: center;

          svg {
            width: 17px;
            color: $grayscale-400;
          }
          .data-text {
            color: $grayscale-400;
            font-size: 14px;
          }
        }

        .btn {
          color: $blue-500;
          font-size: 14px;
          border: 0;
          background: transparent;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
  }
}
</style>