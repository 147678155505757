<template>
  <GridWithAuth class="page-home">
    <HomeHero v-if="false" />
    <HomeMetrics />
    <HomeHeroMetrics v-if="false" />
    <HomeActions />
    <HomeWatched />
  </GridWithAuth>
</template>

<script>
import HomeHero from "./components/HomeHero.vue";
import HomeHeroMetrics from "./components/HomeHeroMetrics.vue";
import HomeActions from "./components/HomeActions.vue";
import HomeWatched from "./components/HomeWatched.vue";
import HomeMetrics from "./components/HomeMetrics.vue";

export default {
  name: "HomeView",
  components: {
    HomeHero,
    HomeHeroMetrics,
    HomeActions,
    HomeWatched,
    HomeMetrics,
  },
};
</script>

<style lang="scss">
.page-home {
  // background-color: $grayscale-100;
  // padding-bottom: 50px;

  .grid-content__page {
    row-gap: 48px;
  }
}
</style>
