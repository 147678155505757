export default {
  namespaced: true,

  state: {
    videosFromMobile: [],
  },

  getters: {
    getVideosFromMobile: state => state.videosFromMobile,
  },

  mutations: {
    SET_VEELS: (state, value) => (state.veels = value),
    SET_VIDEOS_FROM_MOBILE: (state, value) => (state.videosFromMobile = value)
  },

  actions: {
    setVideosFromMobile ({ state }, data) {
      state.videosFromMobile.push({ ...data })
    },

    removeVideosFromMobile({ state }, video) {
      state.videosFromMobile = state.videosFromMobile.filter(v => v.id !== video.id);
    },

    // async veelsLinkTerms ({ dispatch }, { veels_id, terms }) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     value = await window
    //       .$request()
    //       .post(`/link-terms/save`, {
    //         veels_id,
    //         links: terms
    //       });

    //     dispatch('setVeelsDetails', {links: value?.data?.links || []});

    //   } catch (e) {
    //     error = e;
    //   }

    //   return {
    //     error,
    //     value
    //   };
    // },

    // async removeVideoFromVeels({ dispatch, state }, data) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     const response = await window
    //       .$request()
    //       .delete(`/link-videos/${data.veels_id}/${data.id}`)

    //     const videosData = state.veelsDetails.videos.filter(v => v.id !== data.id);

    //     dispatch('setVeelsDetails', {videos: videosData });

    //     value = response.data;
    //   } catch (e) {
    //     console.error('[removeVideoFromLibrary]', error)
    //     error = e;
    //   }

    //   return {
    //     value,
    //     error
    //   };
    // },
  }
}
