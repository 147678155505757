<template>
  <div class="grid-top-menu">
    <div class="grid-top-menu__actions">
      <span
        class="username"
        @click="$router.push({ name: 'account-profile' })"
      >
        <User3LineIcon />
        Olá, {{ getFirstName }}
      </span>

      <div class="notification">
        <button
          class="notification-btn"
          @click="$router.push({ name: 'settings' })"
        >
          <Settings3LineIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GridTopMenu',
  computed: {
    ...mapState(['user']),

    getFirstName() {
      let firstName = '';
      if (this.user?.name?.includes(' ')) {
        firstName = this.user.name.split(' ');
      }

      return firstName ? firstName[0] : this.user?.name;
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-top-menu {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-end;
  width: 100%;
  height: 72px;
  padding: 0 48px;
  background-color: $white;
  border-bottom: 1px solid $grayscale-200;

  &__actions {
    display: flex;
    flex-flow: nowrap;
    height: 100%;
    width: max-content;
    align-items: center;

    .username {
      color: $black-500;
      font-weight: bold;
      margin-right: 39px;
      padding-right: 39px;
      border-right: 1px solid $grayscale-200;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      gap: 10px;

      svg {
        width: 20px;
      }
    }

    .notification {
      &-btn {
        border: 0;
        background: unset;
        cursor: pointer;

        &.has-notification {
          position: relative;

          &:before {
            content: "";
            width: 7px;
            height: 7px;
            background: $primary;
            position: absolute;
            right: -2px;
            top: -2px;
            border-radius: 50%;
            border: 2px solid $white;
          }
        }

        svg {
          width: 20px;
          color: $grayscale-500;
        }
      }
    }
  }
}
</style>
