<template>
  <div class="home-watched__card">
    <div class="video">
      <!-- <img
        src="@/assets/img/veels-card.png"
        alt="Calça Ornella"
      > -->
      <video controls>
        <source
          :src="video?.url"
          type="video/mp4"
        >
        Seu navegador não suporta a tag de vídeo.
      </video>

      <!-- <span class="views">{{ video?.views }} views</span> -->
    </div>

    <div class="info">
      <div class="details">
        <h3 class="name">
          {{ video?.name }}
        </h3>

        <!-- <span class="edit">
          <EditBoxLineIcon />
        </span> -->
      </div>

      <!-- <span class="btn-metrics"> Ver estatísticas </span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeWatchedCard',

  props: {
    video: {
      type: Object,
      default: () => {},
    }
  },

  computed: {
    // videoUrl () {
    //   return }
    // }
  }
};
</script>

<style lang="scss">
.home-watched__card {
  width: 204px;
  gap: 15px;
  display: flex;
  flex-flow: column;

  .video {
    width: 100%;
    height: 278px;
    border-radius: 8px;
    position: relative;

    &:hover {
      .views {
        background: $blue-500;
        color: $white;
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      user-select: none;
    }

    .views {
      position: absolute;
      bottom: 13px;
      right: 11px;
      background: $white;
      font-size: 12px;
      font-weight: 500;
      padding: 4px 8px;
      border-radius: 30px;
      color: $black-500;
      transition: all 0.2s;
      user-select: none;
    }
  }

  .info {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 10px;

    .details {
      display: flex;
      flex-flow: nowrap;
      justify-content: space-between;
      align-items: flex-start;

      .name {
        width: calc(100% - 32px);
        color: $grayscale-500;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        font-family: "Nunito", sans-serif;
      }
      .edit {
        width: 32px;
        height: 32px;
        background: $white;
        border-radius: 50%;
        box-shadow: 0 4px 15px -4px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;

          path {
            stroke: $grayscale-400;
          }
        }
      }
    }

    .btn-metrics {
      color: $blue-500;
      font-weight: 600;
      width: max-content;
      font-size: 13px;
      cursor: pointer;
      font-family: "Nunito", sans-serif;
    }
  }
}
</style>
