<template>
  <button
    class="button-default"
    :class="{ 'is-loading': loading, disabled }"
  >
    <Loader4LineIcon v-if="loading" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss">
.button-default {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $primary;
  border-radius: 50px;
  border: 0;
  color: $white;
  padding: 20px;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  &.is-loading {
    justify-content: center;
  }

  .loader {
    align-self: center;
    width: 20px;
    height: 20px;
    color: $white;
  }

  svg {
    width: 20px;
  }

  &:hover {
    opacity: 0.7;
  }
}
</style>
