<template>
  <GlobalHero class="veels-hero">
    <ButtonImport
      icon="InboxUnarchiveLineIcon"
      @click="emitter.emit('toggle-popup-new-veels', true)"
    >
      <template #text>
        Carregar do dispositivo
      </template>
    </ButtonImport>

    <ButtonImport
      icon="GalleryView2Icon"
      @click="emitter.emit('toggle-popup-new-veels', 'library')"
    >
      <template #text>
        Importar da biblioteca
      </template>
    </ButtonImport>

    <ButtonImport
      icon="SmartphoneLineIcon"
      @click="emitter.emit('toggle-popup-new-veels', 'mobile')"
    >
      <template #text>
        Enviar do celular
      </template>
    </ButtonImport>

    <!-- <ButtonImport icon="FocusLineIcon">
      <template #text>
        Gravação instantânea
      </template>
    </ButtonImport> -->
  </GlobalHero>
</template>

<style lang="scss">
.veels-hero {
  .global-hero__info {
    gap: 20px;
    margin-top: -30px;
    justify-content: flex-start;
  }

  .button-import {
    flex-flow: nowrap !important;
    height: 78px !important;
    justify-content: flex-start !important;
    gap: 20px !important;
    align-items: center !important;

    .text {
      width: max-content !important;
      max-width: max-content !important;
    }
  }
}
</style>
