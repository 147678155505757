import axios from 'axios'
import { getCookie } from '@/config/utils/cookies';

export default () => {
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token') || getCookie('user_token');

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  return axios.create({
    timeout: 60000,
    headers: { ...headers },
    baseURL: process.env.VUE_APP_BACKEND_API
  })
}
