<template>
  <div class="home-hero__metrics">
    <HomeMetricBox :data="views" />
    <HomeMetricBox :data="likes" />
    <HomeMetricBox :data="shares" />
  </div>
</template>

<script>
import { useStore, mapGetters } from "vuex";
import HomeMetricBox from "./HomeMetricBox.vue";

export default {
  components: {
    HomeMetricBox,
  },

  setup () {
    const store = useStore();
    store.dispatch('searchHomeHeroMetrics');
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters(['getHomeHeroMetrics']),
    analytics () {
      return this.getHomeHeroMetrics?.analytics
    },

    views () {
      return {
        value_1:  this.analytics?.clicks || 0,
        id: "views",
        title: "Visualizações",
        description_1: "Quantidade de views",
        percent: null,
      }
    },

    likes () {
      return {
        value_1:  this.analytics?.likes || 0,
        id: "likes",
        title: "Curtidas",
        description_1: "Total de curtidas",
        percent: null,
      }
    },

    shares () {
      return {
        value_1:  this.analytics?.shares || 0,
        id: "shares",
        title: "Compartilhamentos",
        description_1: "Total de compartilhamentos",
        percent: null,
      }
    }
  },

  methods: {
    // ...mapActions(['searchHomeHeroMetrics'])
  }
};
</script>

<style lang="scss" scoped>
.home-hero__metrics {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 28px;
  padding: 0 48px;
  // margin-top: -96px;
  z-index: 1;
  max-width: 1172px;
}
</style>
