<template>
  <GridWithAuth class="page-veels">
    <ReviewsHero />
    <!-- <ReviewsTabs /> -->
    <ReviewsAnalytics />
    <hr>
    <ReviewsList />
  </GridWithAuth>
</template>

<script>
import ReviewsHero from "./components/ReviewsHero.vue";
// import ReviewsTabs from "./components/ReviewsTabs.vue";
import ReviewsList from "./components/ReviewsList.vue";
import ReviewsAnalytics from "./components/ReviewsAnalytics.vue";

export default {
  name: "VeelsView",
  components: {
    ReviewsHero,
    // ReviewsTabs,
    ReviewsAnalytics,
    ReviewsList
  },
};
</script>

<style lang="scss">
.page-veels {
  background-color: $grayscale-100;

  hr {
    display: flex;
    width: calc(100% - 96px);
    margin: 0 48px;
    margin-top: 30px;
    border-top: 1px solid $grayscale-100;
  }
}
</style>
