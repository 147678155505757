<template>
  <div
    id="app"
  >
    <router-view />
    <NewVeels />
  </div>
</template>

<script>
import NewVeels from '@/components/new-veels/index.vue'
import Mqtt from '@/config/mixins/mqtt';
import { mapState } from 'vuex';
// import { mapState } from 'vuex';

export default {
  components: {
    NewVeels
  },
  
  mixins: [Mqtt],

  computed: {
    ...mapState(['user']),

    // mqttTopic() {
    //   return `veels/${this.user?.email}`;
    // }
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler (_user) {
        if (_user?.email) {
          this.initConnectMqtt();
        }
      }
    }
  },

  async created () {
    // this.initConnectMqtt();
    // await Mqtt.connect();
    // Mqtt.subscribe(`veels/mamedio@veels.pro`, this.handleMessage);
  },

  methods: {
    // handleMessage(topic, message) {
    //   console.log(`Mensagem recebida no tópico ${topic}:`, message);
    //   this.receivedMessage = message;
    // },
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Archivo", sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #444;
}

input {
  outline: none;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: $grayscale-100;
}
::-webkit-scrollbar-thumb {
  background: $grayscale-300;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: $grayscale-400;
}
</style>
