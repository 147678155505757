<template>
  <label
    :for="forId"
    class="button-import"
  >
    <span
      class="icon"
      :class="icon"
    >
      <component :is="icon" />
    </span>

    <span class="text">
      <slot name="text" />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: () => '',
      required: false,
    },
    forId: {
      type: String,
      default: () => '',
      required: false,
    }
  }
}
</script>

<style lang="scss">
.button-import {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  background: $grayscale-200;
  border-radius: 8px;
  width: 340px;
  height: 138px;
  padding: 20px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  .icon {
    background: $white;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &.InboxUnarchiveLineIcon {
      svg {
        path {
          fill: $primary;
        }
      }
    }

    &.UploadCloud2LineIcon {
      svg {
        path {
          fill: $purple-500;
        }
      }
    }

    &.FocusLineIcon {
      svg {
        path {
          fill: $red-500;
        }
      }
    }

    &.SmartphoneLineIcon {
      svg {
        path {
          fill: $grayscale-600;
        }
      }
    }

    svg {
      width: 20px;
      path {
        fill: $purple-500;
      }
    }
  }

  .text {
    max-width: 100px;
    text-align: left;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: $black-500;
  }
}
</style>