<template>
  <GlobalHero class="reviews-hero">
    <h1 class="title">
      Avaliações
    </h1>

    <p class="description">
      Saiba o que os seus clientes pensam sobre os seus vídeos
    </p>
  </GlobalHero>
</template>

<style lang="scss">
.reviews-hero {
  .global-hero__info {
    flex-flow: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    gap: 15px;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: $black-500;
    }

    .description {
      font-size: 16px;
      color: $grayscale-600;

    }
  }
}
</style>
