import { setCookie } from '@/config/utils/cookies';

export default {
  namespaced: true,

  state: {
    user: {},
  },

  getters: {
    getUser: state => state.user,
    getPermissions: state => state.permissions
  },

  mutations: {
    SET_USER: (state, value) => (state.user = value)
  },

  actions: {
    async accountCreate ({ commit }, data) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .post('/auth/create', data)

        commit('SET_USER', { ...response.data.user });
        setCookie('user_token', response.data.token)
        value = response.data;
      } catch (e) {
        console.error('[accountCreate]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    async accountLogin ({ commit }, data) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .post('/auth/login', data)

        commit('SET_USER', response.data.user);

        setCookie('user_token', response.data.token)
        value = response.data;
      } catch (e) {
        console.error('[accountLogin]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async accountAuth ({ commit }) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get('/auth/islogged')

        commit('SET_USER', response.data.user);

        setCookie('user_token', response.data.user.token);
        value = response.data.user;
      } catch (e) {
        console.error('[accountAuth]', error)
        error = e;
      }

      if (error) {
        window.$router.push({ name: 'account-login' });
      }

      return {
        value,
        error
      };
    },

    async accountUpdate ({ commit }, data) {
      let value = null;
      let error = null;
      let user_id = data?.id;
      delete data.id;

      if (!user_id) {
        return {
          error: true
        }
      }

      try {
        const response = await window
          .$request()
          .put(`/auth/update/${user_id}`, data)

        commit('SET_USER', { ...response.data.user });
        setCookie('user_token', response.data.user.token)
        value = response.data;
      } catch (e) {
        console.error('[accountCreate]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    accountLogout ({ commit }) {
      commit('SET_USER');
      setCookie('user_token', '');
      window.$router.push({ name: 'account-login' });
    }

    // async removePermission ({ commit }, data) {
    //   try {
    //     const id = window.$route.params.appID

    //     const response = await window
    //       .$request('connect')
    //       .delete(`permissions/dash/${id}`, {
    //         data: {
    //           permission_id: data
    //         }
    //       })

    //     commit('SET_PERMISSIONS', response.data)
    //   } catch (error) {
    //     console.log('[removePermission]', error)
    //   }
    // },

    // async searchPermissions ({ commit }) {
    //   try {
    //     const id = window.$route.params.appID

    //     const response = await window
    //       .$request('connect')
    //       .get(`permissions/dash/${id}`)

    //     commit('SET_PERMISSIONS', response.data)
    //   } catch (error) {
    //     console.log('[searchPermissions]', error)
    //   }
    // },

    // setPermission ({ commit }, data) {
    //   commit('SET_PERMISSION', data)
    // }
  }
}
