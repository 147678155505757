<template>
  <div
    class="popup-confirmation--background"
    @click="setConfirmationPopupActive(false)"
  />
  <div class="popup-confirmation">
    <span
      class="close"
      @click="setConfirmationPopupActive(false)"
    >
      <CloseLineIcon />
    </span>

    <div class="info">
      {{ description }}
    </div>

    <div class="actions">
      <ButtonDefault
        class="cancel"
        @click="$emit('confirmAction', false)"
      >
        Cancelar
      </ButtonDefault>

      <ButtonDefault @click="$emit('confirmAction', true)">
        {{ titleConfirm }}
      </ButtonDefault>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PopupConfirmation',
  
  props: {
    description: {
      type: String,
      default: 'Tem certeza que deseja continuar ?'
    },

    titleConfirm: {
      type: String,
      default: 'Confirmar'
    },

    eventName: {
      type: String,
      default: 'Confirmar'
    }
  },

  emits: [
    'confirmAction',
    'cancelAction'
  ],

  methods: {
    ...mapActions(['setConfirmationPopupActive'])
  }
}
</script>

<style lang="scss" scoped>
.popup-confirmation--background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  z-index: 2;
}
.popup-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 250px;
  background: $white;
  z-index: 3;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;

  .close {
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    svg {
      width: 25px;
    }
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 70px);
    width: 100%;
    padding: 0 25px;
    padding-top: 30px;
    line-height: 30px;
  }

  .actions {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    height: 70px;

    .button-default {
      width: 150px;
      justify-content: center;
      background: $red-500;

      &.cancel {
        background: transparent;
        color: $black-500;
        width: max-content;
      }
    }
  }
}
</style>