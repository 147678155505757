
/**
 * @param phone {string}
 * @returns     {string}
 */
export function usePhoneMask (phone) {
  console.log(phone, 'phone aqui')
  const cleanPhone = (phone || '').replace(/\D+/g, '');

  const len = cleanPhone.length

  if (len < 3) {
    return cleanPhone.replace(/^(\d{1,2})$/, '($1')
  }

  if (len <= 6) {
    return cleanPhone.replace(/^(\d{2})(\d{1,4})$/, '($1) $2')
  }

  if (len <= 10) {
    return cleanPhone.replace(/^(\d{2})(\d{4})(\d{1,4})$/, '($1) $2-$3')
  }

  if (len === 11) {
    return cleanPhone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
  }

  return cleanPhone
}
