<template>
  <div class="comments">
    <div
      v-if="(commentsData || []).length > 0"
      class="comments-list"
    >
      <div
        v-for="comment in commentsData"
        :key="comment.id"
        class="comments-list__item"
      >
        <div class="comments-list__item-container">
          <div class="comments-list__item-content">
            <div class="comments-list__item-info user">
              <div class="comments-list__item-info-container">
                <figure class="image">
                  <span class="first-letter">
                    {{ (comment.name || 'V').charAt(0) }}
                  </span>
                </figure>

                <strong class="name">
                  {{ comment.name }}
                </strong>

                <ButtonDefault
                  v-if="comment.phone"
                  class="phone"
                  @click="whatsappLink(comment?.phone)"
                >
                  <WhatsappLineIcon />

                  <span class="value">{{ comment.phone }}</span>
                </ButtonDefault>
              </div>

              <div class="comments-list__item-info actions">
                <a
                  v-if="!comment?.status"
                  class="btn approve"
                  @click="setCommentApprove(comment?.id)"
                >
                  Aprovar
                </a>

                <a
                  class="btn"
                  :href="goToVeelsDetails(comment?.veels_id)"
                  target="_blank"
                >
                  Ver produto
                </a>
              </div>
            </div>

            <div class="comments-list__item-info comment">
              {{ comment?.comment }}
            </div>
          </div>
        </div>

        <div class="comments-list__item-replies">
          <ul class="list">
            <li
              v-for="reply in comment?.replies"
              :key="reply.id"
              class="item"
            >
              <b>Resposta:</b> {{ reply?.reply }}
            </li>
          </ul>
        </div>

        <div class="comments-list__item-reply">
          <FormKit
            :id="`form-reply-${comment.id}`"
            v-slot="{ state }"
            type="form"
            class="global-tabs__filters"
            :actions="false"
            @submit="sendReply"
          >
            <FormKit 
              type="textarea"
              placeholder="Digite sua resposta"
              name="reply"
              validation="required"
              :validation-messages="{
                required: 'Campo obrigatório'
              }"
            />

            <FormKit
              name="id"
              :value="comment?.id"
              type="hidden"
            />

            <ButtonDefault
              :loading="state.loading"
            >
              Responder
            </ButtonDefault>
          </FormKit>

          <small
            v-if="error[`reply_${comment?.id}`]"
            class="error-message"
          >
            {{ error[`reply_${comment?.id}`] }}
          </small>

          <small
            v-if="success[`reply_${comment?.id}`]"
            class="success-message"
          >
            {{ success[`reply_${comment?.id}`] }}
          </small>
        </div>
      </div>
    </div>

    <div
      v-else
      class="empty"
    >
      Nenhum comentário encontrado.
    </div>

    <Pagination
      v-if="(commentsData || []).length"
      :data="paginationData"
      @navigate="page => setPage(page)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CommentsList',

  data: () => ({
    loading: false,
    error: {},
    success: {}
  }),

  computed: {
    ...mapState(['comments', 'commentsFilters']),

    commentsData() {
      return this.comments?.comments?.data || [];
    },

    paginationData (store) {
      const last_page    = store.comments?.last_page ?? 1
      const current_page = store.comments?.current_page ?? 1

      return {
        last_page,
        current_page
      }
    },

    setPage (store) {
      return (page) => store.commentsFilters.page = page
    }
  },

  watch: {
    commentsFilters: {
      deep: true,
      async handler () {
        await this.searchComments();
      }
    }
  },

  beforeUnmount() {
    this.setComments({});
    this.setCommentsFilters({
      type: '',
      page: 1
    })
  },

  async created() {
    this.loading = true;
    this.setCommentsFilters({
      type: 'to-list',
      page: 1
    })
    this.loading = false;
  },

  methods: {
    ...mapActions([
      'setComments', 
      'searchComments', 
      'sendCommentReply', 
      'setCommentApprove', 
      'setCommentsFilters'
    ]),

    async sendReply (data) {
      this.success = {};
      this.error = {};

      const { error } = await this.sendCommentReply({
        ...data
      });

      if (error) {
        this.error[`reply_${data.id}`] = error.response?.data?.error || 'Erro desconhecido, por favor entre em contato com nossa equipe.';
      } else {
        this.success[`reply_${data.id}`] = 'Sua resposta foi enviada'
        this.$formkit.reset(`form-reply-${data.id}`);
      }

      setTimeout(() => {
        this.error[`reply_${data.id}`] = '';
        this.success[`reply_${data.id}`] = '';
      }, 7000);
    },

    whatsappLink(phone) {
      const cleanPhone = phone.replace(/\D/g, '');
      window.open(`https://api.whatsapp.com/send?phone=55${cleanPhone}`, '_blank');
    },

    goToVeelsDetails(id) {
      return this.$router.resolve({
        name: 'veels-details',
        params: { veels_id: id }
      }).href
    }
  }
}
</script>

<style lang="scss">
.comments {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 50px;
  width: calc(100% - 96px);
  padding: 0px 0px;
  margin: 0 48px;

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: $grayscale-500;
    width: calc(100% - 96px);
    padding: 0px 28px;
    margin: 0 48px;
  }

  .comments-list {
    margin-top: 40px;
    width: 100%;
    gap: 15px;
    display: flex;
    flex-flow: column;
    max-width: 950px;

    &__item {
      display: flex;
      flex-flow: column;
      gap: 20px;
      background: $white;
      box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.07);
      padding: 16px 22px;
      border-radius: 8px;

      &-container {
        display: flex;
        flex-flow: nowrap;
        width: 100%;
        gap: 20px;
      }

      &-content {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        width: 100%;
        gap: 20px;
      }

      &-info {
        display: flex;
        flex-flow: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 33px;
        width: 100%;

        &-container {
          display: flex;
          flex-flow: nowrap;
          align-items: center;
          gap: 20px;
        }

        &.user {
          gap: 20px;

          .image {
            display: flex;
            width: max-content;
            margin: 0;
            position: relative;

            .first-letter {
              width: 48px;
              height: 48px;
              background: $black-500;
              color: $white;
              font-size: 20px;
              font-weight: bold;
              border-radius: 8px;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .name {
            color: $black-500;
            font-size: 14px;
          }

          .phone {
            display: flex;
            flex-flow: nowrap;
            align-items: center;
            gap: 10px;
            background-color: darken($green-500, 10%);
            padding: 4px 10px;
            border-radius: 4px;
            height: max-content;

            .value {
              color: $white;
              font-size: 12px;
            }

            svg {
              width: 19px;
              color: $white;
            }
          }
        }

        &.comment {
          font-size: 14px;
          text-align: left;
          width: 100%;
          line-height: 1.3em;
          border: 1px solid $grayscale-200;
          border-radius: 4px;
          padding: 10px 20px;
          background: $grayscale-100;
        }

        &.actions {
          gap: 10px;
          border-radius: 4px;
          width: max-content;

          .btn {
            color: $black-500;
            font-size: 13px;
            border: 0;
            background: transparent;
            cursor: pointer;
            font-weight: 500;
            padding: 10px;
            text-decoration: unset;
            border-radius: 50px;
            width: max-content;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.approve {
              background: $green-500;
              color: $white;
            }
          }
        }
      }

      &-reply {
        display: flex;
        flex-flow: column;
        align-items: center;
        position: relative;
        width: 100%;

        .formkit-form {
          width: 100%;
          .formkit-outer {
            .formkit-wrapper {
              .formkit-inner {
                .formkit-input {
                  padding: 15px;
                  padding-right: 100px;
                }
              }
            }
          }

          .button-default {
            width: max-content;
            height: 30px;
            font-size: 12px;
            padding: 10px 15px;
            position: absolute;
            right: 10px;
            top: 12px;
            background-color: $black-500;
          }
        }

        .success-message, .error-message {
          margin-top: 5px;
        }
      }

      &-replies {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .list {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          gap: 5px;

          .item {
            list-style: none;
            color: $black-500;
            width: max-content;
            font-size: 14px;
            border-radius: 4px;
            text-align: left;
            background: lighten($grayscale-200, 2%);
            padding: 10px;

            b {
              color: darken($blue-500, 15%);  
            }
          }
        }
      }
    }
  }

  .pagination {
    max-width: 950px; 
  }
}
</style>