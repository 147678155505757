<template>
  <div class="grid-auth">
    <GridSideMenu />

    <div class="grid-content">
      <GridTopMenu />

      <div class="grid-content__page">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import GridSideMenu from "./GridSideMenu";
import GridTopMenu from "./GridTopMenu";

export default {
  components: {
    GridSideMenu,
    GridTopMenu,
  },
};
</script>

<style lang="scss" scoped>
.grid-auth {
  display: flex;
  flex-flow: nowrap;
  width: 100%;
}
.grid-content {
  width: 100%;
  display: flex;
  flex-flow: column;
  max-width: calc(100% - 268px);

  &__page {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: $grayscale-100;
    min-height: calc(100vh - 72px);
  }
}
</style>
