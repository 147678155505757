export default {
  namespaced: true,

  state: {
    comments: {},
    commentsFilters: {
      page: 1,
      per_page: 10,
      type: ''
    },
    commentsDetails: {}
  },

  getters: {
    getComments: state => state.comments,
  },

  mutations: {
    SET_COMMENTS: (state, value) => {
      Object.assign(state.comments, { ...value })
    },
    SET_COMMENTS_DETAILS: (state, value) => (state.commentsDetails = value)
  },

  actions: {
    setCommentsFilters ({ state }, data) {
      Object.assign(state.commentsFilters, {
        ...data
      })
    },

    setComments ({ state }, data) {
      state.comments = data;
    },

    // setCommentsDetails ({ state }, data) {
    //   if (Object.keys(data).length < 1) {
    //     return state.commentsDetails = {}
    //   }

    //   Object.assign(state.commentsDetails, {
    //     ...data
    //   })
    // },

    async searchComments({ commit, state }) {
      let value = null;
      let error = null;

      const {page, per_page, type } = state.commentsFilters
      const queryParams = new URLSearchParams({
        page,
        per_page,
      }).toString()

      try {
        const response = await window
          .$request()
          .get(`/comments/${type}?${queryParams}`)

        commit('SET_COMMENTS', response.data);

        value = response.data;
      } catch (e) {
        console.error('[searchComments]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async sendCommentReply ({ commit, state }, comment) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .post(`/comments/${comment.id}/reply`, comment)

        const arrayComments = state.comments?.comments?.data;

        const updatedComments = arrayComments.map(comment => {
          if (response.data.comment.id === comment.id) {
            comment.replies = [ ...response.data.comment?.replies ];
          }
          return comment;
        });

        commit('SET_COMMENTS', { 
          comments: {
            data: [
              ...updatedComments
            ]
          }
        });
        console.log(commit);
        value = response.data;
      } catch (e) {
        console.error('[sendCommentReply]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    async setCommentApprove ({ commit, state }, id) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .put(`/comments/${id}/approve`);

        const arrayComments = state.comments?.comments?.data;

        const updatedComments = arrayComments.map(comment => {
          if (response.data.comment.id === comment.id) {
            comment = { ...response.data.comment };
          }
          return comment;
        });

        commit('SET_COMMENTS', { 
          comments: {
            data: [
              ...updatedComments
            ]
          }
        });

        console.log(commit);
        value = response.data;
      } catch (e) {
        console.error('[setCommentApprove]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    // async searchCommentsDetails({ commit }, id) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     const response = await window
    //       .$request()
    //       .get(`/comments/${id}`)

    //     commit('SET_COMMENTS_DETAILS', response.data?.comments || {});

    //     value = response.data;
    //   } catch (e) {
    //     console.error('[searchCommentsDetails]', error)
    //     error = e;
    //   }

    //   return {
    //     value,
    //     error
    //   };
    // },

    // async veelsLinkTerms ({ dispatch }, { veels_id, terms }) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     value = await window
    //       .$request()
    //       .post(`/link-terms/save`, {
    //         veels_id,
    //         links: terms
    //       });

    //     dispatch('setVeelsDetails', {links: value?.data?.links || []});

    //   } catch (e) {
    //     error = e;
    //   }

    //   return {
    //     error,
    //     value
    //   };
    // },

    // async veelsUnlinkTerms ({ dispatch, state }, { veels_id, term_id }) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     value = await window
    //       .$request()
    //       .delete(`/link-terms/${veels_id}/${term_id}`);

    //     const links = state.veelsDetails.links.filter(l => l.id !== term_id);
    //     dispatch('setVeelsDetails', {links: [...links]});

    //   } catch (e) {
    //     error = e;
    //   }

    //   return {
    //     error,
    //     value
    //   };
    // },

    // async removeVideoFromVeels({ dispatch, state }, data) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     const response = await window
    //       .$request()
    //       .delete(`/link-videos/${data.veels_id}/${data.id}`)

    //     const videosData = state.veelsDetails.videos.filter(v => v.id !== data.id);

    //     dispatch('setVeelsDetails', {videos: videosData });

    //     value = response.data;
    //   } catch (e) {
    //     console.error('[removeVideoFromLibrary]', error)
    //     error = e;
    //   }

    //   return {
    //     value,
    //     error
    //   };
    // },
  }
}
