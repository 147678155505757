<template>
  <div class="veels-details-products">
    <div class="veels-details-products__content">
      <div class="veels-details-products__content-search">
        <div class="field">
          <SearchLineIcon />

          <FormKit
            v-model="searchValue"
            type="text"
            placeholder="Digite o SKU ou cole a URL"
          />
        </div>

        <div
          v-if="searchValue"
          class="cards"
        >
          <div
            class="card"
          >
            <span class="icon">
              <AddLineIcon />
            </span>

            <span class="name">
              {{ searchValue }}
            </span>

            <button
              class="link"
              @click="setSearchedProduct"
            >
              {{ loadingLink ? 'Aguarde..' : 'Adicionar' }}
            </button>
          </div>
        </div>

        <div
          v-else
          class="empty"
        >
          Encontre/adicione seus produtos para vincular aos seus veels.
        </div>
      </div>

      <div class="veels-details-products__content-linkeds">
        <strong class="title">Produtos vinculados</strong>

        <div
          v-if="(veelsDetails.links || []).length"
          class="cards"
        >
          <div
            v-for="(item, i) in veelsDetails.links"
            :key="i"
            class="card"
          >
            <span class="icon">
              {{ detectURLOrSKU(item.value) }}
            </span>

            <!-- <img
              src="@/assets/img/veels-card.png"
              alt="T-shirt"
            > -->

            <span class="name">{{ item.value }}</span>

            <button
              class="unlink"
              @click="unlinkProductVeels(item.id)"
            >
              Desvincular
            </button>
          </div>
        </div>

        <div
          v-else
          class="empty"
        >
          Você ainda não adicionou nenhum produto
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'VeelsDetailsProducts',

  data: () => ({
    searchValue: '',
    searchValues: [],
    loadingLink: false,
    loadingUnlink: false,
  }),

  computed: {
    ...mapState(['veelsDetails'])
  },

  watch: {
    veelsDetails: {
        deep: true,
        immediate: true,
        handler(details) {
            if ((details?.links || []).length) {
                this.searchValues.push(...details?.links) 
            }     
        }
    }
  },

  methods: {
    ...mapActions(['veelsLinkTerms', 'veelsUnlinkTerms']),

    setSearchedProduct () {
    //   this.searchValues.push({
    //     value: this.searchValue, 
    //     attribute: this.detectURLOrSKU(this.searchValue)
    //   });

      this.linkProductVeels({
        value: this.searchValue, 
        attribute: this.detectURLOrSKU(this.searchValue)
      })

      this.searchValue = '';
    },

    removeProduct(index) {
      this.searchValues.splice(index, 1);
    },

    detectURLOrSKU (url) {
      let regex = /^\/.*/;

      if (regex.test(url)) {
       return 'url'; 
      }

      regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      return regex.test(url) ? 'url' : 'sku';
    },

    async linkProductVeels(terms) {
        this.loadingLink = true;
        await this.veelsLinkTerms({ 
            veels_id: this.veelsDetails.id,
            terms: [terms]
        })
        this.loadingLink = false;
    },

    async unlinkProductVeels(term_id) {
        this.loadingUnlink = true;
        await this.veelsUnlinkTerms({ 
            veels_id: this.veelsDetails.id,
            term_id 
        })
        this.loadingUnlink = false;
    }
  },
}
</script>

<style lang="scss">
.veels-details-products {
  display: flex;
  flex-flow: column;

  &__content {
    display: flex;
    flex-flow: nowrap;
    gap: 50px;

    &-search {
      gap: 25px;
      display: flex;
      flex-flow: column;
      align-items: center;
      min-width: 382px;

      .field {
        display: flex;
        position: relative;
        width: max-content;
        height: 40px;
        width: 342px;
        padding: 0 25px;
        padding-left: 55px;
        border: 1px solid $grayscale-300;
        border-radius: 30px;
        align-items: center;

        svg {
          position: absolute;
          left: 20px;
          width: 20px;
          color: $grayscale-400;
        }

        .formkit-outer {
          .formkit-wrapper {
            .formkit-inner {
              input {
                width: 100%;
                height: 38px;
                border: 0;
                color: $black-500;

                &::placeholder {
                  color: $grayscale-300;
                }
              }
            }
          }
        }
      }
    }

    .cards {
      display: flex;
      flex-flow: column;
      gap: 10px;
      max-height: 315px;
      overflow-y: auto;
      width: 382px;
      align-items: flex-start;

      .card {
        background: $grayscale-100;
        padding: 10px 24px;
        border-radius: 10px;
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: calc(100% - 20px);

        .icon {
          width: 48px;
          height: 48px;
          background: $grayscale-200;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          text-transform: uppercase;

          svg {
            color: $grayscale-500;
            width: 23px;
            height: 23px;
          }
        }

        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 4px;
        }

        .name {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $black-500;
          width: calc(100% - 48px - 20px - 15px - 34px);
          text-align: left;
        }

        .link, .unlink {
          color: $blue-500;
          font-weight: 500;
          background: unset;
          border: unset;
          cursor: pointer;
        }

        .unlink {
          color: $red-500;
        }
      }
    }

    &-linkeds {
      border: 1px solid $grayscale-300;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
      min-width: 424px;

      .title {
        color: $black-500;
      }

      .cards {
        .card {
          background: transparent;
          padding: 0;

          .name {
            width: calc(100% - 140px);
            text-align: left;
          }
        }
      }
    }
  }

  .empty {
    font-size: 15px;
    color: $grayscale-400;
    max-width: 300px;
    line-height: 25px;
    text-align: left;
  }
}
</style>