<template>
  <div class="global-hero">
    <div class="global-hero__info">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.global-hero {
  // background: $grayscale-500;
  width: 100%;
  height: 176px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 48px;
  position: relative;

  &__info {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: calc(1172px - 96px);
  }
}
</style>
