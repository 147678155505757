<template>
  <div class="success-veels">
    <span class="check-icon" />
    <span class="text">PRONTO! Agora os seus <b>veels</b> já estão publicados no site. </span>
    <ButtonDefault @click="initNewVeels()">
      Novo Veels
      <AddLineIcon />
    </ButtonDefault>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'SuccessVeels',

  methods: {
    ...mapActions(['setNewVeelsStep', 'setNewVeelsForm']),
    initNewVeels () {
      this.setNewVeelsForm({});
      this.setNewVeelsStep('upload');
    }
  }
}
</script>

<style lang="scss" scoped>
.success-veels {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


  .check-icon {
    width: 100px;
    height: 100px;
    background: $green-500;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 20px;
      border-left: 10px solid $white;
      border-bottom: 10px solid $white;
      transform: rotate(-45deg) translate(5px, -2px);
    }
  }

  .text {
    color: $black-500;
  }

  .button-default {
    width: 212px;
  }
}
</style>