<template>
  <GlobalTabs
    :tabs="tabs"
    @set-searched-term="getSearchedTerm"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "VeelsTabs",
  computed: {
    tabs () {
      return [
        { label: 'Veels', value: 'veels' },
        // { label: 'Produtos', value: 'products' },
        // { label: 'Comentários', value: 'comments' }
      ]
    }
  },

  methods: {
    ...mapActions(['setVeelsFilters']),
    getSearchedTerm (term) {
      this.setVeelsFilters({
        name: term,
        page: 1
      })
    }
  }
}
</script>