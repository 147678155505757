<template>
  <div class="home-watched">
    <h2 class="title">
      Últimos lançados
    </h2>

    <div class="home-watched__cards">
      <div
        v-if="(videos || []).length > 0"
        class="swiper swiper-home-watched"
      >
        <div class="swiper-wrapper">
          <template
            v-for="(video, vid) in videos"
            :key="video.id"
          >
            <div
              v-if="vid < 10"
              class="swiper-slide"
            >
              <HomeWatchedCard
                
                :video="video"
              />
            </div>
          </template>
        </div>
        <div class="swiper-button-next" />
        <div class="swiper-button-prev" />
      </div>

      <div
        v-else
        class="empty"
      >
        Você ainda não possui nenhum Veels criado,
        <a
          class="new-veels"
          @click="emitter.emit('toggle-popup-new-veels', true)"
        >clique aqui</a> para criar um novo.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HomeWatchedCard from "./HomeWatchedCard.vue";

export default {
  name: 'HomeWatched',

  components: {
    HomeWatchedCard,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['homeShowcaseVideos']),
    
    videos() {
      return this.homeShowcaseVideos?.videos?.data || [];
    }
  },

  async created() {
    this.loading = true;
    await this.searchHomeShowcaseVideos();
    this.startVideosSwiper();

    this.loading = false;
  },

  methods: {
    ...mapActions(['searchHomeShowcaseVideos']),
    startVideosSwiper() {
      //eslint-disable-next-line
      new Swiper(".swiper-home-watched", {
        slidesPerView: 'auto',
        spaceBetween: 25,
        navigation: {
          nextEl: ".swiper-home-watched .swiper-button-next",
          prevEl: ".swiper-home-watched .swiper-button-prev",
        },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-watched {
  padding: 52px;
  margin: 0 48px;
  width: calc(100% - 96px);
  background: $white;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  border-radius: 10px;
  border: 1px solid $grayscale-300;
  max-width: calc(1172px - 96px);
  margin-bottom: 48px;

  .title {
    color: $black-500;
    font-size: 16px;
    width: max-content;
  }

  &__cards {
    display: flex;
    flex-flow: nowrap;
    gap: 35px;
    width: 100%;

    .swiper {
      margin: 0;
      .swiper-wrapper {
        .swiper-slide {
          width: 204px;
        }
      }

      .swiper-button-next, .swiper-button-prev {
        background: $white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0 0 15px -3px rgba(0,0,0,0.3);

        &:after {
          color: $black-500;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  .empty {
    color: $grayscale-500;

    .new-veels {
      cursor: pointer;
      color: $black-500;
      text-decoration: underline;
    }
  }
}
</style>
