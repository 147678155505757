<template>
  <GridWithoutAuth>
    <AccountContainer
      title="Cadastrar"
      description="Faça o seu cadastro na plataforma"
    >
      <FormKit
        v-slot="{ state }"
        type="form"
        :actions="false"
        form-class="account-form"
        incomplete-message="Por favor, preencha os campos corretamente"
        @submit="accountCreateForm"
      >
        <FormKit
          type="text"
          placeholder="Digite seu e-mail"
          label="E-mail"
          name="email"
          validation="required|email"
          :validation-messages="{
            email: 'Digite um e-mail válido',
            required: 'E-mail obrigatório'
          }"
        />

        <FormKit
          type="text"
          placeholder="Digite seu nome"
          label="Nome"
          name="name"
          validation="required"
          :validation-messages="{
            required: 'Nome obrigatório'
          }"
        />

        <FormKit
          type="password"
          name="password"
          placeholder="Digite sua senha"
          label="Senha"
          validation="required|length:6"
          :validation-messages="{
            length: 'Senha precisa ter no mínimo 6 digitos',
            required: 'Senha obrigatória'
          }"
        />

        <FormKit
          name="password_confirm"
          type="password"
          label="Confirmar senha"
          placeholder="••••••••"
          validation="confirm"
          :validation-messages="{
            confirm: 'As senhas não são iguais',
          }"
        />

        <small
          v-if="error"
          class="error-message"
        >
          {{ error }}
        </small>

        <div class="actions">
          <ButtonDefault
            class="signup"
            type="button"
            @click="$router.push({ name: 'account-login' })"
          >
            Já tenho uma conta
          </ButtonDefault>

          <ButtonDefault
            :loading="state?.loading"
          >
            Salvar e continuar
            <ArrowRightSLine />
          </ButtonDefault>
        </div>
      </FormKit>
    </AccountContainer>
  </GridWithoutAuth>
</template>

<script>
import { mapActions } from 'vuex';
import AccountContainer from '../components/AccountContainer.vue';

export default {
  components: {
    AccountContainer,
  },

  data: () => ({
    error: ''
  }),

  methods: {
    ...mapActions(['accountCreate']),

    async accountCreateForm (data) {
      this.error = "";

      const { error } = await this.accountCreate({
        ...data
      });

      if (error) {
        this.error = 'Falha ao criar a conta, reveja os campos ou entre em contato.';
        return;
      }

      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-without-auth {
  min-height: 100vh;
  background: $secondary;

  .formkit-form {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .actions {
      display: flex;
      flex-flow: nowrap;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;

      .button-default {
        width: max-content;
        align-self: flex-end;
        width: 200px;

        &.signup {
          background: transparent;
          color: $black-500;
          font-size: 16px;
          justify-content: center;
          width: max-content;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>