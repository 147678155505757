<template>
  <div class="global-tabs">
    <ul class="global-tabs__links">
      <li
        v-for="tab in tabs"
        :key="tab.value"
        class="link"
        :class="{ active: value === tab.value }"
        @click="setActiveTab(tab.value)"
      >
        {{ tab.label }}
      </li>
    </ul>

    <FormKit
      v-if="hasSearch"
      type="form"
      class="global-tabs__filters"
      :actions="false"
      @submit="sendSearchedTerm"
    >
      <div class="global-tabs__filters-input">
        <SearchLineIcon />
        <input
          v-model="term"
          placeholder="Buscar.."
        >

        <span
          v-if="(term || '').length > 0"
          class="clear"
          @click="sendSearchedTerm('clear')"
        >
          <CloseLineIcon />
        </span>
      </div>

      <button
        class="global-tabs__filters-button"
        @click="sendSearchedTerm"
      >
        <Filter3FillIcon />
      </button>
    </FormKit>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },

    hasSearch: {
      type: Boolean,
      default: () => true
    }
  },

  emits: ['setSearchedTerm', 'setTabValue'],

  data: () => ({
    term: '',
    value: '',
  }),
  
  created() {
    if ((this.tabs || []).length > 0) {
      this.value = this.tabs[0].value;
    }
  },

  methods: {
    sendSearchedTerm (status = '') {
      this.term = status === 'clear' ? '' : this.term;
      this.$emit('setSearchedTerm', this.term);
    },

    setActiveTab(v) {
      this.value = v;
      this.$emit('setTabValue', v);
    }
  }
}
</script>

<style lang="scss">
.global-tabs {
  background: $white;
  border: 1px solid $grayscale-200;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  width: calc(100% - 96px);
  height: 64px;
  padding: 0px 28px;
  margin: 0 48px;
  border-radius: 8px;
  margin-top: -30px;
  z-index: 2;

  &__links {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    width: max-content;
    gap: 40px;
    height: 100%;

    .link {
      list-style: none;
      color: $grayscale-400;
      font-size: 14px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.active {
        color: $black-500;
        font-weight: bold;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          background: $black-500;
          left: 0;
          bottom: 0;
          border-radius: 30px;
        }
      }
    }
  }

  &__filters {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &-input {
      width: 245px;
      height: 40px;
      border: 1px solid $grayscale-200;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 30px;
      padding-right: 15px;
      padding-left: 45px;

      input {
        width: 100%;
        height: 100%;
        border: 0;
        background: transparent;
        color: $black-500;
      }

      svg {
        width: 17px;
        position: absolute;
        left: 18px;
        color: $grayscale-400;
      }

      .clear {
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 11px;
        display: flex;

        svg {
          left: unset;
        }
      }
    }

    &-button {
      width: 40px;
      height: 40px;
      background: $grayscale-200;
      border-radius: 50%;
      border: 0;
      cursor: pointer;

      svg {
        width: 20px;
        color: $grayscale-400;
      }
    }
  }
}
</style>