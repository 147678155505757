export default {
  namespaced: true,

  state: {
    tutorial: {},
  },

  getters: {
    getTutorial: state => state.tutorial,
  },

  mutations: {
    SET_TUTORIAL: (state, value) => (state.tutorial = value)
  },

  actions: {
    async searchTutorial({ commit }) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get(`/cdn/get-script`)

        commit('SET_TUTORIAL', response.data);

        value = response.data;
      } catch (e) {
        console.error('[searchTutorial]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async updateGoogleID ({ commit }, data) {
      console.log(commit);

      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .put('/auth/analytics', data)

        value = response.data;
      } catch (e) {
        console.error('[updateGoogleID]', error);
        error = e;
      }

      return {
        value,
        error
      };
    }
  }
}
