<template>
  <GridWithAuth class="page-veels">
    <VeelsHero />
    <VeelsTabs />
    <VeelsList />
  </GridWithAuth>
</template>

<script>
import VeelsHero from "./components/VeelsHero.vue";
import VeelsTabs from "./components/VeelsTabs.vue";
import VeelsList from "./components/VeelsList.vue";

export default {
  name: "VeelsView",
  components: {
    VeelsHero,
    VeelsTabs,
    VeelsList
  },
};
</script>

<style lang="scss">
.page-home {
  background-color: $grayscale-100;

  .grid-content__page {
    row-gap: 48px;
  }
}
</style>
